import { FC, useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// components
import { Covid } from "../pages/covid";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import { Welcome } from "../pages/welcome";
import { Login } from "../pages/auth/login";
import { PageNotFound } from "../pages/404";
import { Profile } from "../pages/main/profile";
import { CareTeam } from "../pages/main/careTeam";
import { Maintenance } from "../pages/maintenance";
import { Dashboard } from "../pages/main/dashboard";
import { Telehealth } from "../pages/main/telehealth";
import { SetPassword } from "../pages/auth/setPassword";
import { AddBill } from "../pages/main/billing/addBill";
import { ChangePassword } from "../pages/main/changePassword";
import { ForgetPassword } from "../pages/auth/forgetPassword";
import { Invoices } from "../pages/main/billing/invoicesListing";
import { ClaimFeed } from "../pages/main/billing/claimFeedListing";
import { LabResults } from "../pages/main/reports/listing";
import { AddAppointment } from "../pages/main/appointments/addAppointment";
import { ViewAppointment } from "../pages/main/appointments/viewAppointment";
import { Appointments } from "../pages/main/appointments/appointmentsListing";
// constants
import { AuthContext } from "../context";
import {
  DASHBOARD_ROUTE, FORGET_PASSWORD_ROUTE, LOGIN_ROUTE, INVOICES_ROUTE,
  VIEW_APPOINTMENTS_ROUTE, LAB_RESULTS_ROUTE, CLAIMS_ROUTE, APPOINTMENTS_ROUTE,
  MAINTENANCE_ROUTE, PATIENT_CHANGE_PASSWORD, HOME_ROUTE, PROFILE_ROUTE,
  SET_PASSWORD_ROUTE, CARE_TEAM_ROUTE, COVID_ROUTE, TELEHEALTH_ROUTE,
} from "../constants";

const Routes: FC = (): JSX.Element => {
  const { isLoggedIn } = useContext(AuthContext)

  return (
    <Switch>
      <PublicRoute path={HOME_ROUTE} component={Welcome} exact />
      <PublicRoute path={LOGIN_ROUTE} component={Login} exact />
      <PublicRoute path={COVID_ROUTE} component={Covid} exact />
      <PublicRoute path={SET_PASSWORD_ROUTE} component={SetPassword} exact />
      <PublicRoute path={FORGET_PASSWORD_ROUTE} component={ForgetPassword} exact />
      <PublicRoute path={`${TELEHEALTH_ROUTE}/:id`} component={Telehealth} />

      <Route exact path="/">
        {isLoggedIn ? <Redirect to={DASHBOARD_ROUTE} /> : <Welcome />}
      </Route>

      <PrivateRoute exact path={DASHBOARD_ROUTE} component={Dashboard} />
      <PrivateRoute exact path={PROFILE_ROUTE} component={Profile} />
      <PrivateRoute exact path={CARE_TEAM_ROUTE} component={CareTeam} />
      <PrivateRoute exact path={PATIENT_CHANGE_PASSWORD} component={ChangePassword} />
      <PrivateRoute exact path={VIEW_APPOINTMENTS_ROUTE} component={Appointments} />
      <PrivateRoute exact path={`${APPOINTMENTS_ROUTE}/new`} component={AddAppointment} />
      <PrivateRoute exact path={`${APPOINTMENTS_ROUTE}/:id`} component={ViewAppointment} />
      <PrivateRoute exact path={LAB_RESULTS_ROUTE} component={LabResults} />
      <PrivateRoute exact path={CLAIMS_ROUTE} component={ClaimFeed} />
      <PrivateRoute exact path={`${CLAIMS_ROUTE}/new`} component={AddBill} />
      <PrivateRoute exact path={INVOICES_ROUTE} component={Invoices} />

      <PublicRoute path={MAINTENANCE_ROUTE} component={Maintenance} exact />

      <Route component={PageNotFound} />
    </Switch>
  );
};

export default Routes;
