// packages block
import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { Controller, useFormContext } from "react-hook-form";
import { Box, FormControl, FormHelperText, InputLabel, TextField } from "@material-ui/core";
// utils and interfaces/types block
import { AuthContext } from "../../context";
import { ItemSelectorOption, ItemSelectorProps } from "../../interfacesTypes";
import { EMPTY_OPTION, INITIAL_PAGE_LIMIT, ITEM_MODULE } from '../../constants';
import { renderListOptions, renderLoading, requiredLabel, setRecord } from "../../utils";
import {
  Doctor, Service, useFindAllDoctorListLazyQuery, useFindAllServiceListLazyQuery
} from "../../generated/graphql"

const ItemSelector: FC<ItemSelectorProps> = ({
  name, label, disabled, isRequired, margin, modalName, value, isEdit, searchQuery, onSelect,
  filteredOptions, feeScheduleId, loading, addEmpty
}): JSX.Element => {
  const { user } = useContext(AuthContext)
  const { control, setValue } = useFormContext()
  const [query, setQuery] = useState<string>('')

  const { facilityId } = user || {}
  const [options, setOptions] = useState<ItemSelectorOption[]>([])
  const inputLabel = isRequired ? requiredLabel(label) : label;

  const updatedOptions = useMemo(() => {
    return addEmpty ? [EMPTY_OPTION, ...options] : options
  }, [options, addEmpty])

  const [findAllService] = useFindAllServiceListLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",

    variables: {
      serviceInput: {
        facilityId,
        isActive: true,
        serviceName: query,
        paginationOptions: {
          page: 1,
          limit: INITIAL_PAGE_LIMIT
        }
      }
    },

    onError() {
      setOptions([])
    },

    onCompleted(data) {
      const { findAllServices } = data || {};

      if (findAllServices) {
        const { services } = findAllServices

        !!services && setOptions(renderListOptions<Service>(services as Service[], modalName))
      }
    }
  });

  const [findAllDoctor] = useFindAllDoctorListLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",

    variables: {
      doctorInput: {
        facilityId,
        searchString: query,
        paginationOptions: {
          page: 1,
          limit: INITIAL_PAGE_LIMIT
        }
      }
    },

    onError() {
      return null;
    },

    onCompleted(data) {
      if (data) {
        const { findAllDoctor: { doctors } } = data

        !!doctors && setOptions(renderListOptions<Doctor>(doctors as Doctor[], modalName))
      }
    }
  })

  const fetchList = useCallback(async () => {
    try {
      if (modalName === ITEM_MODULE.Services) await findAllService();
      if (modalName === ITEM_MODULE.Providers) await findAllDoctor();
    } catch (error) { }
  }, [findAllDoctor, findAllService, modalName])

  useEffect(() => {
    fetchList()
  }, [fetchList, query, searchQuery])

  useEffect(() => {
    setQuery('')
  }, [feeScheduleId])

  useEffect(() => {
    if (isEdit) {
      if (value) {
        const { id, name } = value
        modalName === ITEM_MODULE.Services && setValue('serviceId', setRecord(id, name || ''))
        modalName === ITEM_MODULE.Providers && setValue('providerId', setRecord(id, name || ''))
      }
    }
  }, [isEdit, modalName, setValue, value])

  const filterOptions = (options: ItemSelectorOption[]) => {
    if (filteredOptions) {
      return options.filter((value) => !filteredOptions.some(option => option.id === value.name?.split(" |")[0]))
    }

    return options
  }

  return (
    <>
      {loading ? renderLoading(inputLabel || '') :
        <Controller
          rules={{ required: true }}
          name={name}
          control={control}
          defaultValue={options[0]}
          render={({ field, fieldState: { invalid, error: { message } = {} } }) => {
            return (
              <Autocomplete
                filterOptions={filterOptions}
                options={updatedOptions ?? []}
                disableClearable
                value={field.value ?? EMPTY_OPTION}
                disabled={disabled}
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name || ""}
                renderOption={(option) => option.name}
                renderInput={(params) => (
                  <FormControl fullWidth margin={margin || 'normal'} error={Boolean(invalid)}>
                    <Box position="relative">
                      <InputLabel id={`${name}-autocomplete`} shrink>
                        {isRequired ? requiredLabel(label) : label}
                      </InputLabel>
                    </Box>

                    <TextField
                      {...params}
                      variant="outlined"
                      error={invalid}
                      className="selectorClass"
                      onChange={({ target: { value } }) => setQuery(value)}
                    />

                    <FormHelperText>{message}</FormHelperText>
                  </FormControl>
                )}
                onChange={(_, data) => {
                  field.onChange(data)
                  onSelect && onSelect(data)
                }}
              />
            );
          }}
        />
      }
    </>
  );
};

export default ItemSelector;
