// packages block
import { Reducer, useCallback, useContext, useEffect, useReducer } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components block
import PageHeader from "../../common/PageHeader";
import InputController from '../../../controller';
import { Box, Button, Card, Grid, Typography } from "@material-ui/core";
// constants block
import { formatValue } from '../../../utils';
import { AuthContext } from '../../../context';
import { NewAvatarIcon } from "../../../assets/svgs";
import { BLUE_FOUR, WHITE_FOUR } from '../../../theme';
import ViewDataLoader from '../../common/ViewDataLoader';
import { useFormStyles } from '../../../styles/formsStyles';
import MAP_IMAGE from "../../../assets/images/map-image.svg"
import { PatientProviderPayload, useGetPatientProvidersLazyQuery } from '../../../generated/graphql';
import { DISCONNECT, MESSAGE, MY_CARE_TEAM, NOTE, PRIMARY_PROVIDER, SUBMIT } from "../../../constants";
import { patientReducer, Action, initialState, State, ActionType } from "../../../reducers/patientReducer";

const CareTeamComponent = (): JSX.Element => {
  const { user } = useContext(AuthContext)
  const { userId: id } = user || {}
  const [{ patientProvidersData }, dispatch] =
    useReducer<Reducer<State, Action>>(patientReducer, initialState)
  const methods = useForm<any>({
    mode: "all",
  });
  const { handleSubmit } = methods;
  const classes = useFormStyles()
  const onSubmit: SubmitHandler<any> = () => { }

  const [getPatientProvider, { loading }] = useGetPatientProvidersLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",

    onError() { },

    onCompleted(data) {
      if (data) {
        const { getPatientProviders } = data;

        if (getPatientProviders) {

          const { providers } = getPatientProviders;
          dispatch({ type: ActionType.SET_PATIENT_PROVIDERS_DATA, patientProvidersData: providers as PatientProviderPayload['providers'] })
        }
      }
    },
  });


  const fetchAllPatientsProviders = useCallback(async () => {
    try {
      id && await getPatientProvider({
        variables: {
          getPatient: { id }
        }
      })
    } catch (error) { }
  }, [id, getPatientProvider])

  useEffect(() => {
    fetchAllPatientsProviders()
  }, [fetchAllPatientsProviders]);

  return (
    <>
      <PageHeader title={MY_CARE_TEAM} />
      <Box maxHeight="calc(100vh - 260px)" className="overflowY-auto">
        <Grid container spacing={3}>
          <Grid item lg={4} md={5} sm={12} xs={12}>
            <Card>
              <Box p={4}>
                {(loading) ? (
                  <ViewDataLoader columns={12} rows={2} />
                ) : (patientProvidersData?.map((item) => {
                  const { doctor, id, currentProvider } = item || {}
                  const { email, firstName, lastName, speciality } = doctor || {}
                  const doctorName = `${firstName} ${lastName}`
                  return (
                    <>
                      <Box p={3} mb={3} border={`1px dotted ${WHITE_FOUR}`} borderRadius={8} key={id}>
                        <Box mb={2} display="flex" justifyContent='space-between' flexWrap="wrap">
                          <Box display="flex" flexDirection='column'>
                            <Typography variant="h4">{doctorName}</Typography>

                            <Box py={0.2} />

                            <Typography variant="body1">{speciality && formatValue(speciality)}</Typography>

                            <Box py={0.2} />

                            <Box className={classes.emailWrap}>
                              <Typography variant="body1" >{email}</Typography>
                            </Box>
                          </Box>
                        </Box>

                        {currentProvider === true && <Box className={classes.status} component='span' color={BLUE_FOUR} border={`1px solid ${BLUE_FOUR}`}>
                          {PRIMARY_PROVIDER}
                        </Box>}
                      </Box>
                    </>
                  )
                }))}
              </Box>
            </Card>
          </Grid>

          <Grid item lg={8} md={7} sm={12} xs={12}>
            <Card>
              <Box p={4}>
                <Box className={classes.careTeamLocation } mb={5}>
                  <Box display="flex" alignItems="center">
                    <NewAvatarIcon />

                    <Box ml={2}>
                      <Typography variant="h4">William Warren</Typography>

                      <Box py={0.2} />

                      <Typography variant="body1">Cardiac Electrophysiologist</Typography>
                    </Box>
                  </Box>

                  <Box display="flex">
                    <Button variant="outlined" color="default">{DISCONNECT}</Button>

                    <Box p={1} />

                    <Button variant="contained" color="primary">{MESSAGE}</Button>
                  </Box>
                </Box>

                <Box mb={3}>
                  <img src={MAP_IMAGE} alt="" className='fullWidth' />
                </Box>

                <FormProvider {...methods}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={3}>
                      <Grid md={12} item>
                        <InputController
                          fieldType="text"
                          controllerName="providerNote"
                          controllerLabel={NOTE}
                        />
                      </Grid>
                    </Grid>

                    <Box display="flex" justifyContent="flex-end" pt={2}>
                      <Button type="submit" variant="contained" className='blue-button-New'>
                        {SUBMIT}
                      </Button>
                    </Box>
                  </form>
                </FormProvider>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default CareTeamComponent;
