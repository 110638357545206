// packages block
import { FC, useContext } from "react";
import { Link } from "react-router-dom";
import { AppBar, Typography, Box, Toolbar } from '@material-ui/core';
// Components block
import DropdownMenu from "./DropdownMenu";
// utils and header styles block
import history from "../../history";
import { activeClass } from "../../utils";
import { AIMEDLOGO } from "../../assets/svgs";
import { AuthContext } from "../../context";
import { useHeaderStyles } from "../../styles/headerStyles";
import {
  BILLING_TEXT, REPORTS, APPOINTMENT_MENU_ITEMS, LAB_RESULTS_ROUTE,
  BILLING_MENU_ITEMS, ROOT_ROUTE, APPOINTMENT, CARE_TEAM_ROUTE, CARE_TEAM
} from "../../constants";

const HeaderNew: FC = (): JSX.Element => {
  const classes = useHeaderStyles();
  const { patient } = useContext(AuthContext)
  const { firstName, lastName } = patient || {}
  const { location: { pathname } } = history

  const currentRoute = activeClass(pathname || '');

  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <Link to={ROOT_ROUTE}>
          <AIMEDLOGO />
        </Link>

        <Box className={classes.menuBar}>
          {/* <Typography
            component={Link}
            to={ROOT_ROUTE}
            className={currentRoute === 'inDashboard' ? ` ${classes.menuItem} active` : `${classes.menuItem}`}
          >
            {HOME_TEXT}
          </Typography> */}

          <Typography
            component={Link}
            to={CARE_TEAM_ROUTE}
            className={currentRoute === 'inCareTeam' ? ` ${classes.menuItem} active` : `${classes.menuItem}`}
          >
            {CARE_TEAM}
          </Typography>

          <DropdownMenu
            itemName={APPOINTMENT}
            menuItem={APPOINTMENT_MENU_ITEMS}
            current={currentRoute === 'inAppointment'}
          />

          <DropdownMenu
            itemName={BILLING_TEXT}
            menuItem={BILLING_MENU_ITEMS}
            current={currentRoute === 'inBilling'}
          />

          <Typography
            component={Link}
            to={LAB_RESULTS_ROUTE}
            className={currentRoute === 'inReport' ? ` ${classes.menuItem} active` : `${classes.menuItem}`}
          >
            {REPORTS}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="center">
          <Box display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="right"
            maxWidth='200px' 
          >
            <Typography variant="h6" color="textPrimary" noWrap className={classes.profileItem}>{`${firstName} ${lastName}`}</Typography>
          </Box>

          <DropdownMenu avatarIcon={true} />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderNew;
