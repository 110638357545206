// packages block
import { createContext, FC, useCallback, useEffect, useState } from "react";
// components block
import Alert from "../components/common/Alert";
// graphql, interfaces/types and constants block
import { handleLogout } from "../utils";
import { AuthContextProps } from "../interfacesTypes";
import {
  ATTACHMENT_TITLES, PATIENT_NOT_FOUND, TOKEN, USER_NOT_FOUND_EXCEPTION_MESSAGE
} from "../constants";
import {
  User, useGetLoggedInUserLazyQuery, Patient, useGetPatientLazyQuery,
  useGetAttachmentLazyQuery, Doctor, Attachment, DoctorPatientRelationType
} from "../generated/graphql";

export const AuthContext = createContext<AuthContextProps>({
  user: null,
  patient: null,
  doctor: null,
  doctors: [],
  isLoggedIn: false,
  profilePicture: '',
  currentUser: null,
  logoutUser: () => { },
  setIsLoggedIn: () => { },
  setUser: (user: User | null) => { },
  setPatient: (patient: Patient | null) => { },
  setDoctor: (doctor: Doctor | null) => { },
  setDoctors: (doctor: Doctor[]) => { },
  setProfileUrl: (url: string) => { },
  setCurrentUser: (user: Patient | null) => { },
  profileUrl: '',
  fetchUser: () => { },
  fetchAttachment: () => { },
  profileAttachment: null,
  profileLoading: false
});

export const AuthContextProvider: FC = ({ children }): JSX.Element => {
  const hasToken = localStorage.getItem(TOKEN);
  const [user, setUser] = useState<User | null>(null);
  const [patient, setPatient] = useState<Patient | null>(null);

  const [doctor, setDoctor] = useState<Doctor | null>(null);
  const [doctors, setDoctors] = useState<Doctor[]>([]);
  const [isLoggedIn, _setIsLoggedIn] = useState<boolean>(false);

  const [currentUser, setCurrentUser] = useState<Patient | null>(null);
  const [profilePicture] = useState<string>('');
  const [profileUrl, setProfileUrl] = useState('')
  const [profileAttachment, setProfileAttachment] = useState<null | Attachment>(null)

  const [getAttachment, { loading: attachmentLoading }] = useGetAttachmentLazyQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onError() {
      return null
    },

    onCompleted(data) {
      const { getAttachment } = data || {};

      if (getAttachment) {
        const { preSignedUrl } = getAttachment
        preSignedUrl && setProfileUrl(preSignedUrl)
      }
    },
  });

  const [getPatient, { loading: patientLoading }] = useGetPatientLazyQuery({
    fetchPolicy: "network-only",

    onError() {
      Alert.error(PATIENT_NOT_FOUND)
      handleLogout();
    },

    onCompleted(data) {
      const { getPatient } = data || {}

      if (getPatient) {
        const { patient } = getPatient

        if (patient) {
          const { attachments, doctorPatients } = patient
          const doctor = doctorPatients?.filter(doc =>
            doc.relation === DoctorPatientRelationType.PrimaryProvider)[0]

          setDoctor(doctor?.doctor as Doctor)
          const transformedDoctors = doctorPatients?.map((doctorPatient) => {
            return doctorPatient.doctor as Doctor
          }) ?? []

          setDoctors(transformedDoctors)
          setPatient(patient as Patient)
          setCurrentUser(patient as Patient)

          const patientAttachment = attachments?.find(({ title }) => title === ATTACHMENT_TITLES.ProfilePicture);
          patientAttachment && setProfileAttachment(patientAttachment)
        }
      }
    }
  });

  const logoutUser = () => {
    setUser(null)
    setIsLoggedIn(false)
    setCurrentUser(null)

    handleLogout();
  }

  const fetchAttachment = useCallback(async () => {
    try {
      const { id } = profileAttachment || {}

      id && await getAttachment({ variables: { getMedia: { id } } })
    } catch (error) { }
  }, [profileAttachment, getAttachment])

  useEffect(() => {
    profileAttachment && fetchAttachment()
  }, [profileAttachment, fetchAttachment])

  const [fetchUser, { loading: userLoading }] = useGetLoggedInUserLazyQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onError() {
      Alert.error(USER_NOT_FOUND_EXCEPTION_MESSAGE)
      logoutUser();
    },

    async onCompleted(data) {
      if (data) {
        const { me } = data

        if (me) {
          const { user } = me;

          if (user) {
            const { userId } = user
            setUser(user as User);
          
            try {
              userId && await getPatient({
                variables: { getPatient: { id: userId } }
              })
            } catch (error) { }
          }
        }
      }
    },
  });

  const setIsLoggedIn = (isLoggedIn: boolean) => _setIsLoggedIn(isLoggedIn);

  const getUser = useCallback(async () => {
    try {
      await fetchUser();
    } catch (error) { }
  }, [fetchUser]);

  useEffect(() => {
    hasToken && setIsLoggedIn(true);
    isLoggedIn && hasToken && getUser();
  }, [isLoggedIn, hasToken, getUser]);

  useEffect(() => {
    profileAttachment && fetchAttachment()
  }, [profileAttachment, fetchAttachment])

  const profileLoading = attachmentLoading || patientLoading || userLoading

  return (
    <AuthContext.Provider
      value={{
        user,
        doctor,
        doctors,
        setUser,
        patient,
        setDoctor,
        fetchUser,
        setPatient,
        setDoctors,
        isLoggedIn,
        profileUrl,
        logoutUser,
        currentUser,
        setIsLoggedIn,
        setProfileUrl,
        profilePicture,
        profileLoading,
        setCurrentUser,
        fetchAttachment,
        profileAttachment,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
