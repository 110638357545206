// packages
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { AppBar, Box, Toolbar, Typography } from "@material-ui/core";
import { AIMEDLOGO } from "../../../assets/svgs";
// constant, interfaces, graphql
import { useHeaderStyles } from "../../../styles/headerStyles";
import { APPOINTMENT_NOT_FOUND, ROOT_ROUTE } from "../../../constants";
import { AppointmentCreateType, AppointmentPayload, useGetAppointmentLazyQuery } from "../../../generated/graphql";
import Alert from "../../common/Alert";
import BackdropLoader from "../../common/Backdrop";
import { ParamsType } from "../../../interfacesTypes";

const TelehealthComponent = (): JSX.Element => {
  const classes = useHeaderStyles();
  const { id } = useParams<ParamsType>()
  const [appointment, setAppointment] = useState<AppointmentPayload['appointment']>(null)

  const [getAppointment, { loading: getAppointmentLoading }] = useGetAppointmentLazyQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onError({ message }) {
      Alert.error(message)
    },

    onCompleted(data) {
      const { getAppointment: { response, appointment } } = data;

      if (response) {
        const { status } = response

        if (appointment && status && status === 200) {
          setAppointment(appointment as AppointmentPayload['appointment'])
        }
      }
    }
  });
  const fetchAppointment = useCallback(async () => {
    id && await getAppointment({
      variables: { getAppointment: { id } }
    })
  }, [getAppointment, id])

  useEffect(() => {
    id ? fetchAppointment() : Alert.error(APPOINTMENT_NOT_FOUND)
  }, [fetchAppointment, id])

  const { provider, patient, appointmentType } = appointment || {}
  const { name: serviceName } = appointmentType || {}
  const { firstName, lastName, telehealthLink } = provider || {}

  return (
    <>
      {
        getAppointmentLoading ? <BackdropLoader loading={getAppointmentLoading} /> :
          <>
            <AppBar className={classes.appBar}>
              <Toolbar>
                <Link to={ROOT_ROUTE}>
                  <AIMEDLOGO />
                </Link>

                <Box ml={3} className={classes.menuBar}>
                  <Typography variant="body1" color="textPrimary"><strong>Service:</strong> {serviceName}</Typography>
                  <Box p={2} />
                  <Typography variant="body1" color="textPrimary"><strong>Provider:</strong> Dr. {firstName} {lastName}</Typography>
                </Box>
              </Toolbar>
            </AppBar>

            <Box p={1} />

            {
              appointment?.appointmentCreateType === AppointmentCreateType.Telehealth ?
                <Box mt={5} height={`calc(100vh - 64px)`} position='relative'>
                  {patient &&
                    <iframe
                      src={`${telehealthLink}?username=${patient.firstName}&autocheckin=true`}
                      title="AIMED"
                      width='100%'
                      height='100%'
                      allow={`camera ${telehealthLink}; microphone ${telehealthLink}`}
                    >
                    </iframe>}
                </Box> :
                <>This is not a telehealth Appointment</>
            }
          </>
      }
    </>
  )
}

export default TelehealthComponent;