// packages block
import { Box, Grid, Typography } from "@material-ui/core";
// utils, styles and constants block
import { WHITE, } from "../../../theme";
import { sanitizedData } from "../../../utils";
import { useWelcomeStyles } from "../../../styles/welcomeStyles";
import { STAY_DESCRIPTION_ONE, STAY_DESCRIPTION_TWO, STAY_TITLE } from "../../../constants";

const StayComponent = (): JSX.Element => {
  const classes = useWelcomeStyles();

  return (
    <Box bgcolor={WHITE} pt={3}>
      <Box className={classes.containerBox}>
        <Grid container spacing={4} alignItems="center">
          <Grid item md={12} sm={12} xs={12}>
            <Box mb={3} className={classes.bigBorder} />

            <Typography variant="h4" className={classes.title}>{STAY_TITLE}</Typography>

            <Box p={2} />

            <Box py={2} dangerouslySetInnerHTML={sanitizedData(STAY_DESCRIPTION_ONE)} />

            <Box py={2} dangerouslySetInnerHTML={sanitizedData(STAY_DESCRIPTION_TWO)} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default StayComponent;