export const GRAY = "#f9f9f9";
export const GRAY_ONE = "#ECF0F3"
export const GRAY_TWO = "#B5B5C3"
export const GRAY_THREE = "#464E5F"
export const GRAY_FOUR = "#dddddd"
export const GRAY_FIVE = "#F5F8FA"
export const GRAY_SIX = "#EFF2F5"
export const GRAY_SEVEN = "#B1B3BB"
export const GRAY_EIGHT = "#A3A3C7"
export const GRAY_NINE = "#D1D5DB"
export const GRAY_TEN = "#737373"
export const GRAY_ELEVEN = "#F9FAFB"

export const BLACK = "#000000";
export const BLACK_ONE = "#3F4254";
export const BLACK_TWO = "#7E8299";
export const BLACK_THREE = "#4A4B68";
export const BLACK_FOUR = "#181C32";
export const BLACK_FIVE = "#333333";
export const BLACK_SIX = "#6C7293";
export const BLACK_SEVEN = "#262D3D";
export const BLACK_EIGHT = "#6B7280";
export const BLACK_NINE = "#202020";

export const WHITE = "#FFFFFF";
export const WHITE_ONE = "#F2F3F5";
export const WHITE_TWO = "#E5E5E5";
export const WHITE_THREE = "#F3F6F9";
export const WHITE_FOUR = "#E4E6EF;";
export const WHITE_FIVE = "#A1A5B7";
export const WHITE_SIX = "#F1FAFF";

export const GREEN = "#1DC894";
export const GREEN_ONE = "#03CC83";

export const BLUE = "#596ed5"
export const BLUE_ONE = "#009EF6";
export const BLUE_TWO = "#0056B1";
export const BLUE_THREE = "#00A3FF";
export const BLUE_FOUR = "#1BC5BD"
export const BLUE_FIVE = "#C9F7F5"
export const BLUE_SIX = "#009EF7";
export const BLUE_SEVEN = "#3699FF";
export const BLUE_EIGHT = "#204ECF";
export const BLUE_NINE = "#5AACFF";

export const ORANGE = "#FFF9F4";
export const ORANGE_ONE = "#F89C47";

export const GREY_ONE = "#C9CED6";

export const PINK = "#F1416C";

export const RED = "#F64E60";
export const RED_ONE = "#FFE2E5";
export const RED_THREE = "#f44336";
export const RED_FOUR = "#DD1010";