import { makeStyles } from "@material-ui/core";
import { WHITE } from "../theme";
export const useProfileStyles = makeStyles((theme) =>
  ({
    profileContainer: {
      marginTop: 50,
      padding: 50,
      background: WHITE,
      borderRadius: 12,
      display: "flex",
      justifyContent: "space-around",
      alignItems: "flex-start",
      flexWrap: "wrap",

      [theme.breakpoints.between(959, 1130)]: {
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
        },
    },

    profileImage: {
      height: 160,
      width: 180,
      marginLeft: 10,
      borderRadius: 12,

      "& > img": {
        height: "100%",
        width: "100%",
        objectFit: "cover",
      },
    },

    capitalize : {
      textTransform : 'capitalize'
    }
  })
);
