import { makeStyles, createStyles } from "@material-ui/core";
import { GRAY_TWO, theme } from "../theme";

export const useFormStyles = makeStyles(() =>
  createStyles({
    controlLabel: {
      justifyContent: 'space-between',
      marginLeft: 0,
    },

    helperText: {
      color: GRAY_TWO,
    },

    status: {
      fontWeight: 'bold',
      fontSize: '0.75rem',
      borderRadius: 8,
      padding: '8px 15px',
      display: 'inline-block',
    },

    emailWrap: {
      wordBreak: 'break-word'
    },

    careTeamLocation: {
      display: 'flex',
     
      [theme.breakpoints.up("sm")]: {
        justifyContent: 'space-between',
        alignItems: 'center',
      },

      [theme.breakpoints.down("sm")]: {
        flexDirection: 'column',
        alignItems: 'flex-start'
      },

      '& .MuiButton-root':{
        [theme.breakpoints.down("sm")]: {
          marginTop: '0.75rem'
        },
      }
    }
  })
);