// components block
import LabResultsTable from "./LabResultsTable";
import PageHeader from "../../../common/PageHeader";
// constants block
import { REPORTS_BREAD, LAB_RESULTS_BREAD, LAB_RESULTS_TEXT } from "../../../../constants";

const LabResultsComponent = (): JSX.Element => (
  <>
    <PageHeader
      title={LAB_RESULTS_TEXT}
      path={[REPORTS_BREAD, LAB_RESULTS_BREAD]}
      hasComponent
    />

    <LabResultsTable />
  </>
)

export default LabResultsComponent;
