// packages block
import states from "states-us";
// graphql and interfaces block
import { SelectorOption, StepLabelType } from "../interfacesTypes";
import {
  AppointmentsIcon, ReportsIcon, BillingIcon, ConnectEmailIcon, ConnectRegisterIcon,
  ConnectBrowserIcon, ConnectLoginIcon, CoughIcon, FeverIcon, BreathIcon, FatigueIcon,
  WashHandIcon, DistanceIcon, NoTravelIcon, NoTouchIcon, CoverMouthIcon, StayHomeIcon,
} from "../assets/svgs";
import { Communicationtype, Ethnicity, Genderidentity, Homebound, Maritialstatus, PaymentType, Pronouns, Race, RelationshipType, Sexualorientation } from "../generated/graphql";
import { formatValue } from "../utils";

// regex
export const ZIP_REGEX = /^\d{5}$/;
export const NUMBER_REGEX = /^[0-9]+$/;
export const STRING_REGEX = /^[A-Za-z\s]+$/;
export const ADDRESS_REGEX = /^[#.0-9a-zA-Z\s,-]+$/;
export const ALPHABETS_REGEX = /^([A-Za-z]+\s)*[A-Za-z]+$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g;

// constants
export const NOTES = "Notes";
export const EMPTY_OPTION = { id: "", name: "--" };
export enum DAYS {
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
  Sunday = "Sunday",
}
export const ADD = "Add";
export const CANCELLED = "Cancelled";
export const INITIATED = "Initialized";
export const NEXT = "Next";
export const VIEW = "View";
export const FINISH = "Finish";
export const MINUTES = "minutes";
export const USA = "United States";
export const INITIAL_PAGE_LIMIT = 5;
export const LINK_COPIED = "Link Copied";
export const CALENDAR = "Calendar";
export const AUTO_ACCIDENT = "Auto Accident";
export const OTHER_ACCIDENT = "Other Accident";
export const APPOINTMENT_TYPE = "Appointment Type";
export const APPOINTMENT_TYPE_FORMAT = "Sick Visit";
export const PATIENT_CONDITION = "Patient Condition";
export const PRIMARY_INSURANCE = "Primary Insurance";
export const CREATE_APPOINTMENT = "Create Appointment";
export const UPDATE_APPOINTMENT = "Re-schedule Appointment";
export const SECONDARY_INSURANCE = "Secondary Insurance";
export const CONTACT_NUMBER = "Contact Number";
export const DELETE = "Delete";
export const ABOUT_US = "About Us";
export const COVID_19 = "Covid 19";
export const SUPPORT = "Support";
export const FAQS = "FAQs";
export const LOGIN_TEXT = "Login";
export const BACK_SIDE = "Back Side";
export const LOADING_PLEASE_WAIT = "Loading, PLEASE WAIT...";
export const DROP_YOUR_IMAGE_TEXT = "Drop your image here, or browse";
export const SUPPORT_DOC_TEXT = "Supports: JPG, PNG, PDF & DOC";
export const UPLOAD = "Upload";
export const AIMED_COPYRIGHT_TEXT = "© 2022 AIMED Inc.";
export const PLEASE_CLICK_TO_UPDATE_DOCUMENT = "Please click here to update the documents";
export const PLEASE_ADD_DOCUMENT = "Please upload or drag and drop the documents here";
export const SCHEDULE_APPOINTMENT = "Schedule Appointment";
export const EDIT_APPOINTMENT = "Edit Appointment";
export const PRIMARY_PROVIDER = "Primary Provider";
export const PROVIDER = "Provider";
export const SAVE_TEXT = "Save";
export const PASSWORD_TEXT = "Password";
export const GENERAL = "General";
export const SECURITY = "Security";
export const EMAIL = "Email";
export const USER_EMAIL = "emr_patient_user_email";
export const PHONE = "Phone";
export const FAX = "Fax";
export const EDIT = "Edit";
export const ZIP_CODE = "Zip code";
export const ADDRESS = "Address";
export const ADDRESS_2 = "Address 2";
export const CITY = "City";
export const STATE = "State";
export const COUNTRY = "Country";
export const UPLOAD_PICTURE = "Upload Picture";
export const VISIT = "Visit";
export const TEST = "Test";
export const TEST_TAKEN = "Test Taken";
export const PRESCRIBED_BY = "Prescribed By";
export const PROVIDERS = "Providers";
export const REPORTS = "Reports";
export const CARE_TEAM = "Care Team";
export const N_A = "N/A";
export const TYPE = "Type";
export const ADD_BILL = "Add Bill";
export const LOGOUT_TEXT = "Logout";
export const CODE = "Code";
export const TRUE = "TRUE";
export const TEXT = "text";
export const PLAN = "Plan";
export const NONE = "None";
export const NAME = "Name";
export const ROLE = "Role";
export const ROLES = "Roles";
export const ERROR = "error";
export const UNITS = "Units";
export const PAGE_LIMIT = 8;
export const APPOINTMENT_PAGE_LIMIT = 15;
export const INSURANCE = "Insurance";
export const PROFILE_TEXT = "Profile";
export const LAST_APPOINTMENT = "Last Appointment";
export const RESET = "Reset";
export const REASON = "Reason";
export const APPOINTMENT = "Appointment";
export const ALL_APPOINTMENTS = "All Appointments";
export const DAY = "Day";
export const STARTING_TIME = "Starting time";
export const ENDING_TIME = "Ending time";
export const BILLED = "Billed";
export const ZIP = "Zip";
export const SSN = "SSN";
export const ASC = "ASC";
export const DESC = "DESC";
export const DATE = "Date";
export const ACTIVE = "Active";
export const STATUS = "Status";
export const ACTION = "Actions";
export const DRAWER_WIDTH = 300;
export const TAGS_TEXT = "Tags";
export const SUCCESS = "success";
export const CLAIMED = "Claimed";
export const COMMENT = "Comment";
export const SIGN_IN = "Sign In";
export const BACK_TO = "Back to";
export const FOUR_O_FOUR = "404";
export const SERVICE = "Service";
export const ROLE_EVENT = "role";
export const USERS_TEXT = "Users";
export const STAFF_TEXT = "Staff";
export const COMMENTS = "Comments";
export const PASSWORD = "password";
export const ACTIVATE = "Activate";
export const INACTIVE = "Inactive";
export const VERIFIED = "Verified";
export const USER_NAME = "Username";
export const SERVICES = "Services";
export const FACILITY = "Facility";
export const TOKEN = "emr_patient_token";
export const RE_SCHEDULE = "Re-Schedule";
export const CHECK_IN = "Check In";
export const TELEHEALTH = "Telehealth";
export const CHECKED_IN = "Checked In";
export const START_TELEHEALTH = "Start Telehealth";
export const FACILITY_ID = "Facility ID";
export const SETTINGS_TEXT = "Settings";
export const CLIA_ID_NUMBER = "CLIA ID Number";
export const INSURANCE_PLAN_TYPE = "Insurance Plan Type";
export const REVENUE_CODE = "Revenue Code";
export const SERVICE_CODE = "Service Code";
export const STATE_IMMUNIZATION_ID = "State Immunization ID";
export const LOCATION_ID = "Location ID";
export const POS = "Place of Service Code (POS)";
export const TAMXONOMY_CODE = "Tamxonomy Code";
export const USER_ID = "User ID";
export const CANCEL = "Cancel";
export const NOTE = "Note";
export const SUBMIT = "Submit";
export const DISCONNECT = "Disconnect";
export const MESSAGE = "Message";
export const LAST_NAME = "Last Name";
export const BILLING_TEXT = "Billing";
export const REPORTS_TEXT = "Reports";
export const DOCTORS_TEXT = "Doctors";
export const UNVERIFIED = "Unverified";
export const FIRST_NAME = "First Name";
export const PRACTICE_NAME = "Practice Name";
export const PRACTICE_DETAILS = "Practice Details";
export const FACILITY_NAME = "Facilty Name";
export const SEND_EMAIL = "Send Email";
export const START_TIME = "Start Time";
export const END_TIME = "End Time";
export const REQUESTS_TEXT = "Requests";
export const CLINICAL_TEXT = "Clinical";
export const INVOICES_TEXT = "Invoices";
export const PATIENTS_TEXT = "Patients";
export const PATIENT = "Patient";
export const PRACTICE = "Practice";
export const DOCTOR = "Doctor";
export const DOCTOR_SCHEDULE = "Doctor Schedule";
export const DURATION = "Duration";
export const ADD_MEDIA = "Add Media";
export const EDIT_MEDIA = "Edit Media";
export const RECURRING = "Recurring";
export const SUPER_BILL = "Super Bill";
export const PASSWORD_LABEL = "Password";
export const DESCRIPTION = "Description";
export const CREATE_USER = "Create User";
export const DEACTIVATED = "DEACTIVATED";
export const HIDDEN_PASSWORD = "*******";
export const DASHBOARD_TEXT = "Dashboard";
export const ADD_SERVICE = "Add Service";
export const DELETE_RECORD = "Delete Record";
export const CANCEL_RECORD = "Cancel Record";
export const DELETE_PATIENT = "Delete Patient";
export const PHONE_NUMBER = "Phone number";
export const MOBILE_NUMBER = "Mobile number";
export const NEW_PASSWORD = "New password";
export const DELETE_MEDIA = "Delete Media";
export const VERIFY_EMAIL = "Verify Email";
export const SEARCH_PLACEHOLDER = "Search";
export const UNAUTHORIZED = "Unauthorized";
export const MANAGEMENT_TEXT = "Management";
export const VISIT_REASON = "Reason for visit";
export const TOKEN_INVALID = "Token Invalid";
export const RESET_FILTERS = "Reset Filters";
export const NO_DATA_FOUND = "No data found";
export const LAB_RESULTS_TEXT = "Lab Results";
export const MY_CARE_TEAM = "My Care Team";
export const BACK_TO_HOME = "Return Home";
export const PAGE_NOT_FOUND = "Page Not Found";
export const EXCEPTION = "Forbidden exception";
export const DELETE_REQUEST = "Delete Request";
export const EMAIL_VERIFIED = "Email Verified?";
export const APPOINTMENTS_TEXT = "Appointments";
export const UPDATE_PROFILE = "Update Profile";
export const APPOINTMENT_TEXT = "Appointment";
export const CLAIM_FEED_TEXT = "Claim Feed";
export const CHANGE_PASSWORD = "Change password";
export const REPEAT_PASSWORD = "Repeat password";
export const TOKEN_NOT_FOUND = "Token not found";
export const FORGOT_PASSWORD = "Forgot Password?";
export const UPLOAD_DOCUMENT = "Uploaded Document";
export const CONFIRM_PASSWORD = "Confirm password";
export const OLD_PASSWORD = "Old password";
export const CURRENT_PASSWORD = "Current password";
export const UPCOMING_APPOINTMENTS = "Upcoming Appointments";
export const PAST_APPOINTMENTS = "Past Appointments";
export const UPDATE_MEDIA = "UPDATE MEDIA"
export const CONFLICT_EXCEPTION = "Conflict Exception";
export const INSURANCE_CLAIMS_TEXT = "Insurance Claims";
export const CANCEL_APPOINTMENT = "Cancel Appointment";
export const EMPLOYMENT = "Employment";
export const RELOAD = "Reload Page";
export const NOT_FOUND_EXCEPTION = "Not Found Exception";
export const FORBIDDEN_EXCEPTION = "Forbidden Exception";
export const PROFILE_INFORMATION = "Profile information";
export const VIEW_APPOINTMENTS_TEXT = "View Appointments";
export const PASSWORDS_MUST_MATCH = "Passwords must match";
export const CONFIRM_YOUR_PASSWORD = "Confirm your password";
export const NOT_FOUND_EXCEPTION_CAP = "NOT FOUND EXCEPTION";
export const SCHEDULE_APPOINTMENTS_TEXT = "Schedule Appointment";
export const CALENDAR_VIEW_TEXT = "Calendar View";
export const DELETE_RECORD_TEXT = "You are about delete record";
export const CANCEL_APPOINTMENT_DESCRIPTION = "Confirm to cancel appointment";
export const APPOINTMENT_DETAILS = "Appointment Details";
export const MAMMOGRAPHY_CERTIFICATION_NUMBER =
  "Mammography Certification Number";
export const DELETE_MEDIA_DESCRIPTION =
  "Are you sure you want to delete this media?";
export const EMERGENCY_CONTACT_RELATIONSHIP_TO_PATIENT =
  "Emergency Contact Relationship To Patient";
export const YOUR_NAME = "Your Name";
export const SCHEDULE_TEXT = "Schedule";
export const HOME_TEXT = "Home";
export const HELLO_TEXT = "Hello";
export const MY_ACCOUNT_TEXT = "My Account";
export const ACCESS_PORTAL = "Access Portal";
export const SCHEDULE_WITH_DOCTOR = "Schedule with doctor: ";
export const SCHEDULED_IN_FACILITY = "Scheduled in facility: ";
export const SKIP_NOW_TEXT = "Skip for now";
export const FORGOT_PASSWORD_TEXT = "Forgot Password";
export const PATIENT_DETAILS = "Patient Details";
export const SELECT_SERVICES = "Select Services";
export const BOOK_APPOINTMENT = "Book Appointment";
export const NO_SLOT_AVAILABLE = "No Slot available";
export const PATIENT_LAST_NAME = "Patient Last Name";
export const PATIENT_FIRST_NAME = "Patient First Name";
export const VERIFY_EMAIL_HEADING_TEXT = "Verify Your Email";
export const NOTHING_HERE_TEXT = "Seems there is nothing here";
export const MAINTENANCE_IN_PROGRESS = "Maintenance in progress";
export const PASSWORD_CHANGE_HEADING_TEXT = "Password is changed";
export const AGREEMENT_HEADING = "User data privacy & TOS agreement.";
export const EMAIL_NOT_RECEIVE_TEXT = "Did’t receive an email? Try Again";
export const PATIENT_CANCELLED_APPOINTMENT = "Patient cancelled appointment";
export const ACCEPTABLE_PDF_FILES = [".pdf", ".docx", ".doc"];
export const ACCEPTABLE_ONLY_IMAGES_FILES = [".jpg", ".jpeg", ".png", ".svg"];
export const ACCEPTABLE_FILES = [".jpg", ".jpeg", ".png", ".docx", ".doc", ".pdf", ".mp3", ".svg"];
export const ACCEPTABLE_PDF_AND_IMAGES_FILES = [".jpg", ".jpeg", ".png", ".pdf", ".docx", ".doc", ".svg"];
export const NO_WHITE_SPACING_AT_BOTH_ENDS_ERROR_MESSAGE = "White-spaces at beginning or ending is not acceptable";
export const NO_SPACE_AT_BOTH_ENDS_REGEX = /^[^\s]+(\s+[^\s]+)*$/;
export const NO_SPECIAL_CHAR_ERROR_MESSAGE = "Special characters (!@#$%^&*) are not acceptable";
export const NO_SPECIAL_CHAR_REGEX = /^[A-Za-z0-9\s]+$/;
export const NO_NUMBER_ERROR_MESSAGE = "Numbers are not acceptable";
export const SLOT_CONFIRMATION_HEADING_TWO =
  "We've sent you a confirmation message & email for your records.";
export const SLOT_CONFIRMATION_SUB_HEADING =
  "Skip some of the paperwork at the clinic by adding more information.";
export const VERIFY_EMAIL_TEXT =
  "We have sent an email to example@aimed.com please follow a link to verify your email";
export const PASSWORD_CHANGE_TEXT =
  "Your password is successfully changed. Please Sign in to your account and start a new project";
export const SLOT_CONFIRMATION_SUB_HEADING_TWO =
  "You can access the information form now or later from your email or text message.";
export const DELETE_RECORD_LEARN_MORE_TEXT =
  "You are about to delete this record permanently. Are you sure you want to delete this record?";
export const CANCEL_APPOINTMENT_TEXT =
  "You are about to cancel this appointment. Are you sure you want to cancel this appointment?";
export const CONSENT_AGREEMENT_LABEL =
  "I agree to the terms & conditions and hereby, authorize AIMED health facilities to keep my personal health record.";
export const EMR_PATIENT_PORTAL_DESCRIPTION =
  "The easy way to manage your personal health records from one portal. Connect with your providers to get your timely checkup appointment.";
export const CONTROL_DESCRIPTION =
  "Securely connect with all your medical providers with AIMED Patient Portal, trusted by over 13 million users. Gain access to your information via web app";
export const HOW_TO_CONNECT = "How to Connect";
export const LEARN_MORE = `<a href="https://www.who.int/health-topics/coronavirus#tab=tab_1" target="_blank">Learn More</a>`;
export const SYMPTOMS = "Symptoms";
export const PREVENTION = "Prevention";
export const EMERGENCY_CASE = "Emergency Case";
export const BEST_DENTAL = "Best dental surgeons";
export const QUICK_EXAMINATION = "Quick examination";
export const CONTROL_TITLE = "Take control of your medical records.";
export const YOU_CAN_TITLE = "Through the AIMED Patient Portal, you can";
export const WELCOME_TO_AIMED_PATIENT_PORTAL = "Welcome to AIMED Patient Portal";
export const COVID_UPDATES = "COVID-19 Updates";
export const IMPORTANT_COVID_UPDATES = "Important COVID-19 Updates";
export const QUICK_EXAMINATION_DESCRIPTION =
  "The gradual accumulation of information about";
export const STAY_TITLE = "Stay Informed on Prevention & Tracking Updates";
export const TESTING_TITLE = "Testing";
export const WHAT_TITLE = "What Is It?";
export const WHAT_DESCRIPTION =
  "According to the World Health Organization, COVID-19 is an infectious disease caused by a newly discovered coronavirus. While those who are older or have underlying medical conditions- such as respiratory disease or diabetes- are more likely to develop serious side effects, most are able to recover without special treatment.";
export const STAY_DESCRIPTION_ONE = `We strongly encourage everyone, from providers to business owners and patients, to stay up-to-date on the latest information from the CDC regarding <a href="https://www.cdc.gov/coronavirus/2019-ncov/prepare/prevention.html" target="_blank">prevention</a> and <a href="https://www.cdc.gov/coronavirus/2019-ncov/community/large-events/index.html" target="_blank">social gatherings</a>. You should also check your state or county’s public health website for updates on rules and restrictions in your area, as some areas have already implemented shelter-in-place orders. Experts urge individuals to minimize exposure by washing hands often, regularly cleaning surfaces, avoiding frequent contact with eyes and mouth and avoiding all social gatherings over 10 people.`;
export const STAY_DESCRIPTION_TWO = `You can also track the location of confirmed and active cases within the United States using the <a href="https://www.arcgis.com/apps/opsdashboard/index.html#/bda7594740fd40299423467b48e9ecf6" target="_blank">Johns Hopkins Interactive tracking map</a>. However, due to a shortage of testing supplies, even the most reliable sources of information may not accurately portray the full scope of the virus.`;
export const TESTING_DESCRIPTION = `While the CDC provides <a href="https://www.cdc.gov/coronavirus/2019-ncov/hcp/index.html" target="_blank">guidance on who should be tested</a>, the decision is usually made at the discretion of healthcare providers and local agencies. Due to a testing shortage and the fact that not everyone needs to be tested, the CDC website emphasizes that:`;

// routes paths
export const ROOT_ROUTE = "/";
export const HOME_ROUTE = "/home";
export const LOGIN_ROUTE = "/login";
export const COVID_ROUTE = "/covid19";
export const PROFILE_ROUTE = "/profile";
export const INVOICES_ROUTE = "/invoices";
export const DASHBOARD_ROUTE = "/dashboard";
export const CARE_TEAM_ROUTE = "/care-team";
export const TELEHEALTH_ROUTE = "/telehealth";
export const MAINTENANCE_ROUTE = "/maintenance";
export const LAB_RESULTS_ROUTE = "/lab-results";
export const CLAIMS_ROUTE = "/insurance-claims";
export const APPOINTMENTS_ROUTE = "/appointments";
export const SET_PASSWORD_ROUTE = "/set-password";
export const FORGET_PASSWORD_ROUTE = "/forget-password";
export const VIEW_APPOINTMENTS_ROUTE = "/view-appointments";
export const PATIENT_CHANGE_PASSWORD = "/patient-change-password";

// HELPER TEXT MESSAGES
export const MIN_LENGTH_MESSAGE = `Text too short`;
export const REQUIRED_MESSAGE = "This field is required";
export const PASSWORD_NOT_MATCHED = "Password doesn't match";
export const DOB_VALIDATION_MESSAGE = "Date of birth is invalid";
export const ZIP_VALIDATION_MESSAGE = "Valid zip code is 5-digit long";
export const BANK_ACCOUNT_VALIDATION_MESSAGE = "Invalid bank account.";
export const SSN_VALIDATION_MESSAGE = "SSN valid format is NNN-NN-NNNN";
export const ValidMessage = (fieldName: string) =>
  `Please enter valid ${fieldName.toLowerCase()}`;
export const MaxLength = (fieldName: string, length: number) =>
  `${fieldName} can be up to ${length} characters long`;
export const MinLength = (fieldName: string, length: number) =>
  `${fieldName} should be at least ${length} characters long`;
export const PASSWORD_VALIDATION_MESSAGE =
  "Password must contain 8 characters, one uppercase, one lowercase, one number and one special case character";

// ALERT MESSAGES
export const LOGIN_SUCCESSFULLY = "Welcome to AIMED";
export const CANCEL_TIME_EXPIRED_MESSAGE = "Appointment is to be scheduled in less than 1 hour, It can't be cancelled now";
export const SLOT_ALREADY_BOOKED = "This patient has already a booked appointment with this provider.";
export const APPOINTMENT_SLOT_ERROR_MESSAGE = "Appointment Time is required. Please select available slot!";
export const CANT_BOOK_APPOINTMENT = "You can not book this appointment.";
export const RESET_TOKEN_EXPIRED = "Reset password token is expired. Please generate a new one!";
export const APPOINTMENT_BOOKED_SUCCESSFULLY = "Appointment is booked successfully";
export const APPOINTMENT_UPDATED_SUCCESSFULLY = "Appointment is re-scheduled successfully";
export const CANT_UPDATE_APPOINTMENT = "Appointment can't be updated.";
export const PLEASE_SELECT_MEDIA = "Please select a file to upload!";
export const FACILITY_NOT_FOUND = "Facility not found!";
export const APPOINTMENT_NOT_FOUND = "Appointment not found!";
export const PATIENT_NOT_FOUND = "Patient not found!";
export const SERVICE_NOT_FOUND = "Service not found!";
export const FAILED_TO_UPDATE_PATIENT = "Failed to update patient!";
export const TRY_AGAIN = "Something went wrong. Try again!";
export const INVALID_EMAIL = "Invalid email address";
export const CHECKED_IN_SUCCESSFULLY = "Checked in successfully!";
export const APPOINTMENT_CANCEL = "Your appointment is cancel successfully";
export const CANT_CANCELLED_APPOINTMENT = "Appointment can't be cancelled.";
export const PATIENT_CREATED = "Patient created successfully!";
export const SOMETHING_WENT_WRONG = "Something went wrong!";
export const TOKEN_EXPIRED = "Verification token is expired.";
export const USER_EXIST = "User already exists with this email.";
export const OTHER_TEXT='Other'
export const PATIENT_UPDATED = "Patient updated successfully!";
export const USER_NOT_FOUND_EXCEPTION_MESSAGE = "User not found.";
export const NO_USER_WITH_EMAIL = "No user found with this email.";
export const OLD_PASSWORD_DID_NOT_MATCH = "Old password didn't match!";
export const APPOINTMENT_NOT_FOUND_EXCEPTION = "Appointment not found";
export const EMAIL_OR_USERNAME_ALREADY_EXISTS = "Email already exists!";
export const ALREADY_DEACTIVATED_MESSAGE = "User is already deactivated.";
export const ADMIN_PORTAL_NEW_MESSAGE =
  "Enter your credentials to login to your portal";
export const RESET_PASSWORD_MESSAGE = "Please enter your new secure password.";
export const RESET_PASSWORD_TOKEN_NOT_FOUND = "Reset password token not found.";
export const ONLY_PATIENT_MESSAGE = "Only patients can access Patient Portal!";
export const SET_PASSWORD_SUCCESS = "Your password has been set successfully.";
export const SET = "Set Password";
export const PRECONDITION_FAILED_EXCEPTION = "Precondition Failed Exception";
export const PRECONDITION_FAILED_EXCEPTION_MESSAGE =
  "Resource can't be deleted.";
export const WRONG_EMAIL_OR_PASSWORD =
  "You have entered wrong email or password";
export const RESET_PASSWORD_SUCCESS =
  "Your password has been changed successfully.";
export const INVALID_OR_EXPIRED_TOKEN_MESSAGE =
  "Sorry! Your token is expired or invalid.";
export const LOGGED_OUT_BEFORE_RESETTING_PASSWORD =
  "Please log out before resetting password";
export const FORGOT_PASSWORD_MESSAGE =
  "Please enter your email to get a reset-password link.";
export const FORGET_PASSWORD_SUCCESS =
  "An email has been sent to your registered email address";
export const CANT_VERIFY_EMAIL_WHILE_LOGGED_IN_MESSAGE =
  "You can't verify a email while you are logged in.";
export const EMAIL_CHANGED_OR_NOT_VERIFIED_MESSAGE =
  "Email changed or not verified, please verify your email";
export const INVALID_OR_EXPIRED_VERIFICATION_TOKEN_MESSAGE =
  "Sorry! Your verification token is expired or invalid";
export const EXPIRE_TOKEN_MESSAGE =
  "Your token has been expired. Please click on the button below to get an email again.";
export const LOREM_TEXT_15 =
  "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sapiente atque explicabo debitis inventore delectus quos!";

export const APP_MENU_ITEMS = [
  {
    name: APPOINTMENTS_TEXT,
    Icon: AppointmentsIcon,
    index: 4,
    items: [
      {
        name: VIEW_APPOINTMENTS_TEXT,
        link: VIEW_APPOINTMENTS_ROUTE,
      },
    ],
  },
  {
    name: CLINICAL_TEXT,
    items: [],
    index: 5,
    sectionName: true,
  },
  {
    name: REPORTS_TEXT,
    Icon: ReportsIcon,
    index: 7,
    items: [
      {
        name: LAB_RESULTS_TEXT,
        link: LAB_RESULTS_ROUTE,
      },
    ],
  },
  {
    name: BILLING_TEXT,
    Icon: BillingIcon,
    index: 8,
    items: [
      {
        name: INVOICES_TEXT,
        link: INVOICES_ROUTE,
      },
      {
        name: INSURANCE_CLAIMS_TEXT,
        link: CLAIMS_ROUTE,
      },
    ],
  },
];

export const APPOINTMENT_MENU_ITEMS = [
  {
    name: VIEW_APPOINTMENTS_TEXT,
    link: VIEW_APPOINTMENTS_ROUTE,
  },
  // {
  //   name: CALENDAR_VIEW_TEXT,
  //   link: ''
  // },
];

export const GENERAL_MENU_ITEMS = [
  {
    name: PROFILE_TEXT,
    link: PROFILE_ROUTE,
  },
];

export const SECURITY_MENU_ITEMS = [
  {
    name: CHANGE_PASSWORD,
    link: PATIENT_CHANGE_PASSWORD,
  },
];

export const BILLING_MENU_ITEMS = [
  {
    name: INVOICES_TEXT,
    link: INVOICES_ROUTE,
  },
  {
    name: INSURANCE_CLAIMS_TEXT,
    link: CLAIMS_ROUTE,
  },
];

export const MAPPED_COUNTRIES: SelectorOption[] = [{ id: USA, name: USA }];
export const MAPPED_STATES: SelectorOption[] = states.map(
  ({ name, abbreviation }) => ({ id: name, name: `${name} - ${abbreviation}` })
);

export const MAPPED_MARITAL_STATUS: SelectorOption[] = [
  { id: Maritialstatus.Single, name: formatValue(Maritialstatus.Single) },
  { id: Maritialstatus.Widowed, name: formatValue(Maritialstatus.Widowed) },
  { id: Maritialstatus.Divorced, name: formatValue(Maritialstatus.Divorced) },
  { id: Maritialstatus.Separated, name: formatValue(Maritialstatus.Separated) },
];

export const MAPPED_HOMEBOUND: SelectorOption[] = [
  { id: Homebound.No, name: Homebound.No },
  { id: Homebound.Yes, name: Homebound.Yes },
];

export const MAPPED_PRONOUNS: SelectorOption[] = [
  { id: Pronouns.He, name: formatValue(Pronouns.He) },
  { id: Pronouns.She, name: formatValue(Pronouns.She) },
  { id: Pronouns.None, name: formatValue(Pronouns.None) },
];

export const MAPPED_RACE: SelectorOption[] = [
  { id: Race.Other, name: formatValue(Race.Other) },
  { id: Race.Asian, name: formatValue(Race.Asian) },
  { id: Race.White, name: formatValue(Race.White) },
  {
    id: Race.BlackAfricanAmerican,
    name: formatValue(Race.BlackAfricanAmerican),
  },
  {
    id: Race.BlackAfricanAmerican,
    name: formatValue(Race.BlackAfricanAmerican),
  },
  {
    id: Race.AmericanIndianAlaskaNative,
    name: formatValue(Race.AmericanIndianAlaskaNative),
  },
  {
    id: Race.NativeHawaiianPacificIslander,
    name: formatValue(Race.NativeHawaiianPacificIslander),
  },
];

export const MAPPED_ETHNICITY: SelectorOption[] = [
  {
    id: Ethnicity.HispanicOrLatino,
    name: formatValue(Ethnicity.HispanicOrLatino),
  },
  {
    id: Ethnicity.NotHispanicOrLatino,
    name: formatValue(Ethnicity.NotHispanicOrLatino),
  },
  {
    id: Ethnicity.DeclineToSpecify,
    name: formatValue(Ethnicity.DeclineToSpecify),
  },
];

export const MAPPED_SEXUAL_ORIENTATION: SelectorOption[] = [
  { id: Sexualorientation.None, name: formatValue(Sexualorientation.None) },
  {
    id: Sexualorientation.DontKnow,
    name: formatValue(Sexualorientation.DontKnow),
  },
  {
    id: Sexualorientation.Bisexual,
    name: formatValue(Sexualorientation.Bisexual),
  },
  {
    id: Sexualorientation.Homosexual,
    name: formatValue(Sexualorientation.Homosexual),
  },
  {
    id: Sexualorientation.Heterosexual,
    name: formatValue(Sexualorientation.Heterosexual),
  },
];

export const MAPPED_GENDER_IDENTITY: SelectorOption[] = [
  { id: Genderidentity.None, name: formatValue(Genderidentity.None) },
  { id: Genderidentity.Male, name: formatValue(Genderidentity.Male) },
  { id: Genderidentity.Female, name: formatValue(Genderidentity.Female) },
];

export const MAPPED_PAYMENT_METHOD: SelectorOption[] = [
  { id: PaymentType.Self, name: formatValue(PaymentType.Self) },
  { id: PaymentType.Insurance, name: formatValue(PaymentType.Insurance) },
];

export const MAPPED_COMMUNICATION_METHOD: SelectorOption[] = [
  { id: Communicationtype.Email, name: formatValue(Communicationtype.Email) },
  {
    id: Communicationtype.Message,
    name: formatValue(Communicationtype.Message),
  },
  { id: Communicationtype.Phone, name: formatValue(Communicationtype.Phone) },
  {
    id: Communicationtype.VoiceMessage,
    name: formatValue(Communicationtype.VoiceMessage),
  },
];

export const MAPPED_RELATIONSHIP_TYPE: SelectorOption[] = [
  { id: RelationshipType.Ward, name: formatValue(RelationshipType.Ward) },
  { id: RelationshipType.Self, name: formatValue(RelationshipType.Self) },
  { id: RelationshipType.Child, name: formatValue(RelationshipType.Child) },
  { id: RelationshipType.Other, name: formatValue(RelationshipType.Other) },
  { id: RelationshipType.Mother, name: formatValue(RelationshipType.Mother) },
  { id: RelationshipType.Spouse, name: formatValue(RelationshipType.Spouse) },
  { id: RelationshipType.Father, name: formatValue(RelationshipType.Father) },
  { id: RelationshipType.Unknown, name: formatValue(RelationshipType.Unknown) },
  {
    id: RelationshipType.Employee,
    name: formatValue(RelationshipType.Employee),
  },
  {
    id: RelationshipType.OrganDonor,
    name: formatValue(RelationshipType.OrganDonor),
  },
  {
    id: RelationshipType.Grandchild,
    name: formatValue(RelationshipType.Grandchild),
  },
  {
    id: RelationshipType.LifePartner,
    name: formatValue(RelationshipType.LifePartner),
  },
  {
    id: RelationshipType.Grandparent,
    name: formatValue(RelationshipType.Grandparent),
  },
  {
    id: RelationshipType.NephewNiece,
    name: formatValue(RelationshipType.NephewNiece),
  },
  {
    id: RelationshipType.FosterChild,
    name: formatValue(RelationshipType.FosterChild),
  },
  {
    id: RelationshipType.CadaverDonor,
    name: formatValue(RelationshipType.CadaverDonor),
  },
  {
    id: RelationshipType.SignificantOther,
    name: formatValue(RelationshipType.SignificantOther),
  },
  {
    id: RelationshipType.EmancipatedMinor,
    name: formatValue(RelationshipType.EmancipatedMinor),
  },
  {
    id: RelationshipType.InjuredPlaintiff,
    name: formatValue(RelationshipType.InjuredPlaintiff),
  },
  {
    id: RelationshipType.SponsoredDependent,
    name: formatValue(RelationshipType.SponsoredDependent),
  },
  {
    id: RelationshipType.StepsonStepdaughter,
    name: formatValue(RelationshipType.StepsonStepdaughter),
  },
  {
    id: RelationshipType.ChildMotherInsurance,
    name: formatValue(RelationshipType.ChildMotherInsurance),
  },
  {
    id: RelationshipType.HandicappedDependent,
    name: formatValue(RelationshipType.HandicappedDependent),
  },
  {
    id: RelationshipType.ChildFatherInsurance,
    name: formatValue(RelationshipType.ChildFatherInsurance),
  },
  {
    id: RelationshipType.DependentOfMinorDependent,
    name: formatValue(RelationshipType.DependentOfMinorDependent),
  },
  {
    id: RelationshipType.StepsonStepdaughterStepmotherInsurance,
    name: formatValue(RelationshipType.StepsonStepdaughterStepmotherInsurance),
  },
  {
    id: RelationshipType.StepsonStepdaughterStepfatherInsurance,
    name: formatValue(RelationshipType.StepsonStepdaughterStepfatherInsurance),
  },
];

export const StepperIcons: { [index: string]: number } = { 1: 1, 2: 2, 3: 3 };

export const getSteps = (): StepLabelType[] => {
  return [
    {
      title: "Patient Information",
      subTitle: "Provide basic Patient Information",
    },
    {
      title: "Consent Agreement",
      subTitle: "Provide basic Patient Information",
    },
  ];
};

export const agreementPoints = [
  LOREM_TEXT_15,
  LOREM_TEXT_15,
  LOREM_TEXT_15,
  LOREM_TEXT_15,
  LOREM_TEXT_15,
  LOREM_TEXT_15,
];

// Breadcrumb links
export const APPOINTMENT_NEW_BREAD = {
  text: SCHEDULE_APPOINTMENT,
  link: `${APPOINTMENTS_ROUTE}/new`,
};
export const APPOINTMENT_EDIT_BREAD = { text: EDIT_APPOINTMENT, link: "" };
export const BILL_NEW_BREAD = { text: ADD_BILL, link: CLAIMS_ROUTE };
export const DASHBOARD_BREAD = { text: DASHBOARD_TEXT, link: DASHBOARD_ROUTE };
export const APPOINTMENTS_BREAD = { text: APPOINTMENTS_TEXT, link: "" };
export const BILLING_BREAD = { text: BILLING_TEXT, link: "" };
export const REPORTS_BREAD = { text: REPORTS_TEXT, link: "" };
export const CARE_TEAM_BREAD = { text: CARE_TEAM, link: CARE_TEAM_ROUTE };
export const LAB_RESULTS_BREAD = {
  text: LAB_RESULTS_TEXT,
  link: LAB_RESULTS_ROUTE,
};
export const CLAIM_FEED_BREAD = { text: CLAIM_FEED_TEXT, link: CLAIMS_ROUTE };
export const INVOICES_BREAD = { text: INVOICES_TEXT, link: INVOICES_ROUTE };
export const VIEW_APPOINTMENTS_BREAD = {
  text: VIEW_APPOINTMENTS_TEXT,
  link: VIEW_APPOINTMENTS_ROUTE,
};

export enum ITEM_MODULE {
  Services = 'Services',
  Providers = 'Providers'
}

export enum ATTACHMENT_TITLES {
  ProfilePicture = "Profile Picture",
  InsuranceCard1 = "Insurance Card 1",
  InsuranceCard2 = "Insurance Card 2",
  DrivingLicense1 = "Driving License 1",
  DrivingLicense2 = "Driving License 2",
}

export const LANDING_CONNECT_DATA = [
  {
    icon: ConnectEmailIcon,
    title: "STEP 1",
    description:
      "Ask your provider to send you an email invite to the AIMED Patient Portal.",
  },
  {
    icon: ConnectRegisterIcon,
    title: "STEP 2",
    description:
      "Open the AIMED Patient Portal invite email, press the “sign up” button and register online.",
  },
  {
    icon: ConnectBrowserIcon,
    title: "STEP 3",
    description:
      "Open the AIMED Patient Portal from any of the internet browser.",
  },
  {
    icon: ConnectLoginIcon,
    title: "STEP 4",
    description:
      "Click “Log In” option, provides credentials and log into your portal",
  },
];

export const YOU_CAN_DATA = [
  "Fill out any paperwork before your appointment to make checking in easier",
  "Schedule and cancel appointments from your computer or mobile device",
  "View your upcoming appointments and check-in online",
  "Access your medical record",
  "Manage your contact and insurance information",
  "Have educational material sent directly to your account",
  "Message your doctor through a HIPAA compliant portal",
  "View statements and pay online",
];

// Appointment Tabs
export const APPOINTMENT_TABS = [
  {
    title: "Upcoming Appointments",
    value: "1",
  },
  {
    title: "Past Appointments",
    value: "2",
  },

];

export const HEADER_ITEMS = [
  {
    name: HOME_TEXT,
    link: HOME_ROUTE,
  },
  {
    name: ABOUT_US,
    link: "/",
  },
  {
    name: SUPPORT,
    link: "/",
  },
  {
    name: FAQS,
    link: "/",
  },
];

export const FOOTER_ITEMS = [
  {
    name: "FHIR API Documentation",
    link: "/",
  },
  {
    name: "Terms of Service",
    link: "/",
  },
  {
    name: "Privacy Policy",
    link: "/",
  },
  {
    name: "Support",
    link: "/",
  },
];

export const SYMPTOMS_DATA = [
  {
    icon: CoughIcon,
    title: "Dry Cough",
  },
  {
    icon: FeverIcon,
    title: "Fever",
  },
  {
    icon: BreathIcon,
    title: "Shortness of Breath",
  },
  {
    icon: FatigueIcon,
    title: "Fatigue",
  },
];

export const AppointmentSearchingTooltipData = [
  {
    name: `${APPOINTMENT_TYPE}:`,
    format: APPOINTMENT_TYPE_FORMAT,
  },
];

export const PREVENTION_DATA = [
  {
    icon: WashHandIcon,
    description:
      "Wash your hands regularly with soap and water, or clean them with alcohol-based hand rub.",
  },
  {
    icon: DistanceIcon,
    description:
      "Maintain at least a 6 ft distance between others while out in public",
  },
  {
    icon: NoTravelIcon,
    description: "Practice physical distancing by avoiding unnecessary travel.",
  },
  {
    icon: NoTouchIcon,
    description: "Avoid touching your face.",
  },
  {
    icon: CoverMouthIcon,
    description: "Cover your mouth and nose when coughing or sneezing.",
  },
  {
    icon: StayHomeIcon,
    description: "Stay home if you feel unwell.",
  },
];

export const TESTING_DATA = [
  `Most people have <a href="https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/symptoms.html" target="_blank">mild illness</a> and are able to <a href="https://www.cdc.gov/coronavirus/2019-ncov/if-you-are-sick/steps-when-sick.html" target="_blank">recover at home</a>.`,
  "There is no treatment specifically approved for this virus.",
  "Testing results may be helpful to inform decision-making about who you come in contact with.",
];

// system roles
export enum SYSTEM_ROLES {
  EmergencyAccess = "emergency-access",
  SuperAdmin = "super-admin",
  PracticeAdmin = "practice-admin",
  FacilityAdmin = "facility-admin",
  Doctor = "doctor",
  DoctorAssistant = "doctor-assistant",
  Staff = "staff",
  OfficeManager = "office-manager",
  NursePractitioner = "nurse-practitioner",
  Nurse = "nurse",
  FrontDesk = "front-desk",
  Patient = "patient",
}
