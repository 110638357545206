// packages block
import { createContext, FC, useEffect, useCallback, useReducer, Reducer, useContext } from "react";
// graphql, interfaces/types, reducer and constants block
import { TOKEN } from "../constants";
import { AuthContext } from "./authContext";
import { AppContextInterface } from "../interfacesTypes";
import { FacilityPayload, useGetFacilityLazyQuery } from "../generated/graphql";
import {
  Action, ActionType, initialState, appContextReducer, State as LocalState
} from '../reducers/appContextReducer';

export const AppContext = createContext<AppContextInterface>({
  currentFacility: undefined,
  setCurrentFacility: () => { },
  fetchCurrentFacility: () => { },
});

export const AppContextProvider: FC = ({ children }): JSX.Element => {
  const { user } = useContext(AuthContext);
  const { facility } = user || {};
  const { id: facilityId } = facility || {};

  const hasToken = localStorage.getItem(TOKEN);
  const [{ currentFacility }, dispatch] =
    useReducer<Reducer<LocalState, Action>>(appContextReducer, initialState)

  const [getFacility] = useGetFacilityLazyQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onError() { },

    onCompleted(data) {
      const { getFacility } = data || {};

      if (getFacility) {
        const { response, facility } = getFacility

        if (response) {
          const { status } = response

          if (facility && status && status === 200) {
            setCurrentFacility(facility as FacilityPayload['facility'])
          }
        }
      }
    }
  });

  const fetchCurrentFacility = useCallback(async (id: string) => {
    id && await getFacility({
      variables: { getFacility: { id } }
    })
  }, [getFacility]);

  useEffect(() => { }, [user]);
  useEffect(() => { hasToken && fetchCurrentFacility(facilityId || '') }, [facilityId, fetchCurrentFacility, hasToken]);

  const setCurrentFacility = (facility: FacilityPayload['facility']) =>
    dispatch({ type: ActionType.SET_CURRENT_FACILITY, currentFacility: facility });

  return (
    <AppContext.Provider
      value={{
        currentFacility,
        setCurrentFacility,
        fetchCurrentFacility,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
