// packages block
import { useEffect } from "react";
//components block
import YouCanComponent from "./youCan";
import ConnectComponent from "./connect";
import ControlComponent from "./control";
import HeroComponent from "../../common/WelcomePage/Hero";
import HeaderComponent from "../../common/WelcomePage/Header";
import FooterComponent from "../../common/WelcomePage/Footer";
// utils and constants block
import history from "../../../history";
import { getToken } from "../../../utils";
import { DASHBOARD_ROUTE } from "../../../constants";

const WelcomeComponent = (): JSX.Element => {
  useEffect(() => {
    getToken() && history.push(DASHBOARD_ROUTE)
  }, []);

  return (
    <>
      <HeaderComponent />
      <HeroComponent />
      <ControlComponent />
      <ConnectComponent />
      <YouCanComponent />
      <FooterComponent />
    </>
  )
}

export default WelcomeComponent;