// packages block
import { Link } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@material-ui/core";
// constants, history, styling block
import { useWelcomeStyles } from "../../../styles/welcomeStyles";
import {
  ACCESS_PORTAL, COVID_ROUTE, COVID_UPDATES, EMR_PATIENT_PORTAL_DESCRIPTION, LOGIN_ROUTE,
  WELCOME_TO_AIMED_PATIENT_PORTAL
} from "../../../constants";

const HeroComponent = (): JSX.Element => {
  const classes = useWelcomeStyles();

  return (
    <Box className={classes.heroBox} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Grid container justifyContent="center">
        <Grid item md={6} sm={12} xs={12}>
          <Typography variant="h4" className={classes.heroTitle}>{WELCOME_TO_AIMED_PATIENT_PORTAL}</Typography>

          <Box p={2.5} />

          <Typography variant="h4" className={classes.heroDescription}>{EMR_PATIENT_PORTAL_DESCRIPTION}</Typography>

          <Box mt={6} display='flex' justifyContent='center' alignItems='center' flexWrap='wrap'>
            <Button variant="contained" color="primary" component={Link} to={LOGIN_ROUTE} className={classes.buttonLink}>{ACCESS_PORTAL}</Button>

            <Button
              variant="outlined" color="inherit" component={Link} to={COVID_ROUTE}
              className="outlinedButton"
            >
              {COVID_UPDATES}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default HeroComponent;
