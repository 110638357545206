// packages block
import { ChevronRight } from "@material-ui/icons";
import { Box, Button, Grid, Typography } from "@material-ui/core";
// utils, styles and constants block
import { sanitizedData } from "../../../utils";
import { GRAY_SIX, } from "../../../theme";
import { useWelcomeStyles } from "../../../styles/welcomeStyles";
import { LEARN_MORE, WHAT_DESCRIPTION, WHAT_TITLE } from "../../../constants";

const WhatComponent = (): JSX.Element => {
  const classes = useWelcomeStyles();

  return (
    <Box bgcolor={GRAY_SIX} py={3}>
      <Box className={classes.containerBox}>
        <Grid container spacing={4} alignItems="center">
          <Grid item md={12} sm={12} xs={12}>
            <Box mb={3} className={classes.bigBorder} />

            <Typography variant="h4" className={classes.title}>{WHAT_TITLE}</Typography>

            <Box p={4} />

            <Typography variant="body1" className={classes.description}>{WHAT_DESCRIPTION}</Typography>

            <Box p={4} />

            <Button variant="text" color="inherit" className="blue-button-New">
              <Box ml={1} dangerouslySetInnerHTML={sanitizedData(LEARN_MORE)} />
              <ChevronRight />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default WhatComponent;