// packages block
import { Box, Grid, Typography } from "@material-ui/core";
// constants, history, styling block
import { BLACK_SEVEN, GRAY_SIX } from "../../../theme";
import { useWelcomeStyles } from "../../../styles/welcomeStyles";
import { HOW_TO_CONNECT, LANDING_CONNECT_DATA } from "../../../constants";

const ConnectComponent = (): JSX.Element => {
  const classes = useWelcomeStyles();

  return (
    <Box bgcolor={GRAY_SIX} py={3}>
      <Box className={classes.containerBox}>
        <Grid container spacing={4} alignItems="center">
          <Grid item md={6} sm={12} xs={12}>
            <Box mb={3} className={classes.bigBorder}></Box>

            <Typography variant="h4" className={classes.title}>{HOW_TO_CONNECT}</Typography>

            <Box p={4} />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          {LANDING_CONNECT_DATA.map((item, index) => {
            return (
              <Grid item key={index} md={6} sm={12} xs={12}>
                <Box mb={5} display="flex" alignItems="center" flexWrap="wrap">
                  <Box>
                    {item.icon}
                  </Box>

                  <Box ml={2}>
                    <Typography variant="h6"><strong>{item.title}</strong></Typography>

                    <Box pt={1} maxWidth={360} color={BLACK_SEVEN}>
                      <Typography variant="body1">{item.description}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </Box>
  )
}

export default ConnectComponent;