import { makeStyles } from "@material-ui/core/styles";
import LoginBgImage from "../../src/assets/images/login-bg.svg";
import { BLACK_EIGHT, GRAY_ELEVEN, GREEN_ONE, WHITE } from "../theme";

export const useLoginStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    minHeight: "calc(100vh - 68px)",
    backgroundImage: `url(${LoginBgImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: 'column',
    },
  },

  loginFormImageContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: 'column',
    justifyContent: "space-around",
    textAlign: 'center',
  },

  loginDescription: {
    color: "#7E8299",
    fontSize: 18,
    fontWeight: 600,
    maxWidth: 380,
    margin: '16px auto 0',
  },

  sideLoginImage: {

    [theme.breakpoints.down("md")]: {
      display: 'none',
    },
  },

  loginFormLoginContainer: {
    width: '100%',
    maxWidth: 480,
    borderRadius: 8,
    backgroundColor: WHITE,
    padding: theme.spacing(4),

    "& .MuiOutlinedInput-root": {
      backgroundColor: GRAY_ELEVEN,
      minHeight: 48,
      margin: theme.spacing(1,0),
      borderRadius: 4,
    },

    "& .MuiOutlinedInput-input": {
      minHeight: 48,
    },

    "& .MuiButton-contained": {
      backgroundColor: GREEN_ONE,
      borderRadius: 4,
      color: WHITE,
      minHeight: 48,

      "& .MuiButton-label": {
        fontSize: 16,
        fontWeight: 700,
        textTransform: 'capitalize',
      },
    },

  },

  passwordIcon: {
    color: BLACK_EIGHT
  },

  forgotPassword: {
    position: 'absolute',
    right: 0,
    width: 140,
    top: '-25px',
  }
}));
