// packages block
import { Reducer, useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { Edit } from '@material-ui/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Avatar, Box, Button, CircularProgress, Collapse, Grid, } from "@material-ui/core";
//components block
import Alert from '../../common/Alert';
import Selector from '../../common/Selector';
import PhoneField from '../../common/PhoneInput';
import InputController from '../../../controller';
import MediaCards from '../../common/AddMedia/MediaCards';
// constants, history, styling block
import { AuthContext } from '../../../context';
import { ProfileInputProps } from '../../../interfacesTypes';
import { useProfileStyles } from "../../../styles/profileStyles";
import { updateProfileSchema } from '../../../validationSchemas';
import { formatPhone, renderItem, setRecord } from '../../../utils';
import { AttachmentType, Patient, useUpdatePatientProfileMutation } from '../../../generated/graphql';
import {
  patientReducer, Action, initialState, ActionType, State
} from "../../../reducers/patientReducer";
import {
  Action as MediaAction, ActionType as mediaActionType, initialState as mediaInitialState, mediaReducer,
  State as MediaState
} from '../../../reducers/mediaReducer';
import {
  CANCEL, CITY, CONTACT_NUMBER, COUNTRY, EDIT, EMAIL, EMPTY_OPTION, FIRST_NAME, LAST_NAME,
  MAPPED_COUNTRIES, MAPPED_STATES, SAVE_TEXT, STATE, PATIENT_UPDATED, ZIP_CODE, UPLOAD_PICTURE,
  FORBIDDEN_EXCEPTION, EMAIL_OR_USERNAME_ALREADY_EXISTS, ADDRESS, ATTACHMENT_TITLES,
} from "../../../constants";

const ProfileComponent = (): JSX.Element => {
  const {
    patient, setPatient, profileUrl, fetchUser, fetchAttachment, user, profileAttachment, profileLoading
  } = useContext(AuthContext)
  const { userId, email } = user || {}
  const { id } = patient || {}

  const classes = useProfileStyles()
  const [{ patientData }, dispatch] =
    useReducer<Reducer<State, Action>>(patientReducer, initialState)

  const [mediaState, mediaDispatch] =
    useReducer<Reducer<MediaState, MediaAction>>(mediaReducer, mediaInitialState)
  const { attachmentUrl, attachmentData } = mediaState
  const [edit, setEdit] = useState<boolean>(false)

  const methods = useForm<ProfileInputProps>({
    mode: "all",
    resolver: yupResolver(updateProfileSchema)
  });
  const { handleSubmit, setValue, trigger } = methods;

  const { firstName, lastName, contacts } = patientData || {}
  const primaryContact = contacts?.filter(contact => contact.primaryContact)[0]
  const { id: contactId, address, city, state, country, phone, zipCode } = primaryContact || {};

  const [updatePatientProfile, { loading: updatePatientProfileLoading }] = useUpdatePatientProfileMutation({
    onError({ message }) {
      setEdit(false)
      message === FORBIDDEN_EXCEPTION ?
        Alert.error(EMAIL_OR_USERNAME_ALREADY_EXISTS)
        : Alert.error(message)
    },

    onCompleted(data) {
      const { updatePatientProfile: { response, patient } } = data;

      if (response) {
        const { status } = response

        if (patient && status && status === 200) {
          Alert.success(PATIENT_UPDATED);
          setPatient(patient as Patient)
          setEdit(false)
        }
      }
    }
  });

  const handlePreview = () => {
    city && setValue('city', city)
    email && setValue('email', email)
    phone && setValue('phone', phone)
    zipCode && setValue('zipCode', zipCode)
    address && setValue('address', address)
    lastName && setValue('lastName', lastName)
    firstName && setValue('firstName', firstName)
    state && setValue('state', setRecord(state, state))
    country && setValue('country', setRecord(country, country))
  };

  useEffect(() => {
    patient &&
      dispatch({ type: ActionType.SET_PATIENT_DATA, patientData: patient })
  }, [patient]);

  const handleEdit = () => {
    handlePreview();
    setEdit(!edit);
    trigger()
  };

  const onSubmit: SubmitHandler<ProfileInputProps> = async (data) => {
    const { country, state, address, city, firstName, lastName, phone, zipCode } = data
    const { name: selectedState } = state
    const { name: selectedCountry } = country
    const contactInputs = {
      country: selectedCountry, state: selectedState, address, city, phone, zipCode
    }

    id && await updatePatientProfile({
      variables: {
        updatePatientProfileInput: {
          updateContactInput: contactId ? { id: contactId, ...contactInputs } : { ...contactInputs },
          updatePatientProfileItemInput: { id, firstName, lastName }
        }
      }
    })
  }

  const setAttachment = useCallback(async () => {
    const { id: userAttachmentId } = profileAttachment || {}

    profileAttachment &&
      mediaDispatch({ type: mediaActionType.SET_ATTACHMENT_DATA, attachmentData: profileAttachment })

    userAttachmentId &&
      mediaDispatch({ type: mediaActionType.SET_ATTACHMENT_ID, attachmentId: userAttachmentId })

    profileUrl &&
      mediaDispatch({ type: mediaActionType.SET_ATTACHMENT_URL, attachmentUrl: profileUrl })
  }, [profileAttachment, profileUrl])

  useEffect(() => {
    profileUrl && profileAttachment && setAttachment()
  }, [profileUrl, profileAttachment, setAttachment])

  return (
    <Grid container justifyContent='center'>
      <Grid item lg={7} md={10} sm={12} xs={12}>
        <Box className={classes.profileContainer}>
          <Grid container>
            <Grid item md={4} sm={12} xs={12}>
              <Box key={"key"} mt={9} pr={3.75} position="relative">
                {profileLoading ?
                  <Avatar variant="square" className={classes.profileImage}>
                    <CircularProgress size={20} color="inherit" />
                  </Avatar>
                  :
                  <Avatar variant="square" src={attachmentUrl || ""} className={classes.profileImage} />
                }

                <MediaCards
                  title={ATTACHMENT_TITLES.ProfilePicture}
                  reload={() => profileUrl ? fetchAttachment() : fetchUser()}
                  notDescription={true}
                  moduleType={AttachmentType.Patient}
                  itemId={userId || ''}
                  imageSide={attachmentUrl}
                  attachmentData={attachmentData || undefined}
                  buttonText={UPLOAD_PICTURE}
                  button={true}
                />
              </Box>
            </Grid>

            <Grid item md={8} sm={12} xs={12}>
              <Box onClick={handleEdit} mb={3} display="flex" justifyContent="flex-end">
                {edit ?
                  <Button variant="contained" color="secondary">{CANCEL}</Button>
                  :
                  <Button variant="contained" color="primary" startIcon={<Edit />}>{EDIT}</Button>
                }
              </Box>

              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Collapse in={!edit} mountOnEnter unmountOnExit>
                    <Box py={2}>
                      <Grid container spacing={5}>
                        <Grid item md={6} sm={12} xs={12}>
                          <Box maxWidth={200} className={classes.capitalize}>
                            {renderItem(FIRST_NAME, firstName)}
                          </Box>
                        </Grid>

                        <Grid item md={6} sm={12} xs={12}>
                          <Box maxWidth={200} className={classes.capitalize}>
                            {renderItem(LAST_NAME, lastName)}
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container spacing={5}>
                        <Grid item md={12} sm={12} xs={12}>
                          <Box maxWidth={400}>
                            {renderItem(EMAIL, email)}
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container spacing={5}>
                        <Grid item md={12} sm={12} xs={12}>
                          <Box maxWidth={400}>
                            {renderItem(ADDRESS, address)}
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container spacing={5}>
                        <Grid item md={6} sm={12} xs={12}>
                          {renderItem(CONTACT_NUMBER, formatPhone(phone || ''))}
                        </Grid>

                        <Grid item md={6} sm={12} xs={12}>
                          {renderItem(ZIP_CODE, zipCode)}
                        </Grid>
                      </Grid>

                      <Grid container spacing={5}>
                        <Grid item md={6} sm={12} xs={12}>
                          <Box maxWidth={400}>
                            {renderItem(CITY, city)}
                          </Box>
                        </Grid>

                        <Grid item md={6} sm={12} xs={12}>
                          {renderItem(STATE, state)}
                        </Grid>
                      </Grid>

                      <Grid container spacing={5}>
                        <Grid item md={6} sm={12} xs={12}>
                          {renderItem(COUNTRY, country)}
                        </Grid>
                      </Grid>
                    </Box>
                  </Collapse>

                  <Collapse in={edit} mountOnEnter unmountOnExit>
                    <Box py={2}>
                      <Grid container spacing={3}>
                        <Grid item md={6} sm={12} xs={12}>
                          <InputController
                            isRequired
                            fieldType="text"
                            controllerName="firstName"
                            controllerLabel={FIRST_NAME}
                          />
                        </Grid>

                        <Grid item md={6} sm={12} xs={12}>
                          <InputController
                            isRequired
                            fieldType="text"
                            controllerName="lastName"
                            controllerLabel={LAST_NAME}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item md={12} sm={12} xs={12}>
                          <InputController
                            isRequired
                            fieldType="text"
                            controllerName="address"
                            controllerLabel={ADDRESS}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item md={6} sm={12} xs={12}>
                          <PhoneField isRequired name="phone" label={CONTACT_NUMBER} />
                        </Grid>

                        <Grid item md={6} sm={12} xs={12}>
                          <InputController
                            isRequired
                            fieldType="text"
                            controllerName="zipCode"
                            controllerLabel={ZIP_CODE}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item md={6} sm={12} xs={12}>
                          <InputController
                            isRequired
                            fieldType="text"
                            controllerName="city"
                            controllerLabel={CITY}
                          />
                        </Grid>

                        <Grid item md={6} sm={12} xs={12}>
                          <Selector
                            isRequired
                            name="state"
                            label={STATE}
                            value={EMPTY_OPTION}
                            options={MAPPED_STATES}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item md={6} sm={12} xs={12}>
                          <Selector
                            isRequired
                            name="country"
                            label={COUNTRY}
                            value={EMPTY_OPTION}
                            options={MAPPED_COUNTRIES}
                          />
                        </Grid>
                      </Grid>

                      <Box display="flex" justifyContent="flex-start" pt={2}>
                        <Button type="submit" variant="contained" color="primary" disabled={updatePatientProfileLoading}>
                          {SAVE_TEXT}

                          {updatePatientProfileLoading && <CircularProgress size={20} color="inherit" />}
                        </Button>
                      </Box>
                    </Box>
                  </Collapse>
                </form>
              </FormProvider>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}

export default ProfileComponent;
