import { FacilityPayload, PracticePayload } from "../generated/graphql"

export interface State {
  currentPractice: PracticePayload['practice'];
  currentFacility: FacilityPayload['facility'];
}

export const initialState: State = {
  currentPractice: undefined,
  currentFacility: undefined,
}

export enum ActionType {
  SET_CURRENT_PRACTICE = 'setCurrentPractice',
  SET_CURRENT_FACILITY = 'setCurrentFacility',
}

export type Action =
  | { type: ActionType.SET_CURRENT_PRACTICE; currentPractice: PracticePayload['practice'] }
  | { type: ActionType.SET_CURRENT_FACILITY; currentFacility: FacilityPayload['facility'] }

export const appContextReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_CURRENT_PRACTICE:
      return {
        ...state,
        currentPractice: action.currentPractice
      }

    case ActionType.SET_CURRENT_FACILITY:
      return {
        ...state,
        currentFacility: action.currentFacility
      }
  }
};
