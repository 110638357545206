// packages block
import { Link } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@material-ui/core";
//components block
// utils, styles and constants block
import { useWelcomeStyles } from "../../../styles/welcomeStyles";
import { ACCESS_PORTAL, IMPORTANT_COVID_UPDATES, LOGIN_ROUTE } from "../../../constants";


const HeroComponent = (): JSX.Element => {
  const classes = useWelcomeStyles();

  return (
    <Box className={classes.covidBox} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Grid container justifyContent="center">
        <Grid item md={6} sm={12} xs={12}>
          <Typography variant="h4" className={classes.heroTitle}>{IMPORTANT_COVID_UPDATES}</Typography>

          <Box p={4} />

          <Button variant="contained" color="primary" component={Link} to={LOGIN_ROUTE} className={classes.buttonLink}>{ACCESS_PORTAL}</Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default HeroComponent;