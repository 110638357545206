import moment from "moment";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { AppointmentPayload, AppointmentsPayload, SlotsPayload, FacilityPayload } from "../generated/graphql"

export interface State {
  offset: number;
  copied: boolean;
  tabValue: string;
  serviceId: string;
  providerId: string;
  pageComing: number;
  currentDate: string;
  openDelete: boolean;
  searchComingQuery: string;
  searchPastQuery: string;
  facilityName: string;
  facilityId: string;
  isInsurance: boolean;
  appointmentId: string;
  pageCompleted: number;
  totalPagesComing: number;
  totalPagesCompleted: number;
  date: MaterialUiPickersDate;
  deleteAppointmentId: string;
  facility: FacilityPayload['facility'];
  availableSlots: SlotsPayload['slots'];
  upComing: AppointmentsPayload['appointments'];
  appointment: AppointmentPayload['appointment'];
  completed: AppointmentsPayload['appointments'];
  appointments: AppointmentsPayload['appointments'];
}

export const initialState: State = {
  serviceId: '',
  upComing: [],
  pageComing: 1,
  tabValue: '1',
  completed: [],
  copied: false,
  providerId: '',
  facility: null,
  facilityName: '',
  facilityId: '',
  searchComingQuery: '',
  searchPastQuery: '',
  appointments: [],
  pageCompleted: 1,
  openDelete: false,
  appointmentId: '',
  appointment: null,
  isInsurance: false,
  availableSlots: [],
  totalPagesComing: 0,
  totalPagesCompleted: 0,
  deleteAppointmentId: '',
  offset: moment.utc().zone(),
  currentDate: new Date().toDateString(),
  date: new Date() as MaterialUiPickersDate,
}

export enum ActionType {
  SET_DATE = 'setDate',
  SET_COPIED = 'setCopied',
  SET_FACILITY = 'setFacility',
  SET_TAB_VALUE = 'setTabValue',
  SET_UP_COMING = 'setUpComing',
  SET_COMPLETED = 'setComplete',
  SET_SERVICE_ID = 'setServiceId',
  SET_PAGE_COMING = 'setPageComing',
  SET_PROVIDER_ID = 'setProviderId',
  SET_OPEN_DELETE = 'setOpenDelete',
  SET_APPOINTMENT = 'setAppointment',
  SET_COMING_SEARCH_QUERY = 'setComingSearchQuery',
  SET_PAST_SEARCH_QUERY = 'setPastSearchQuery',
  SET_CURRENT_DATE = 'setCurrentDate',
  SET_IS_INSURANCE = 'setIsInsurance',
  SET_APPOINTMENTS = 'setAppointments',
  SET_FACILITY_NAME = 'setFacilityName',
  SET_FACILITY_ID = 'setFacilityId',
  SET_PAGE_COMPLETED = 'setPageCompleted',
  SET_APPOINTMENT_ID = 'setAppointmentId',
  SET_AVAILABLE_SLOTS = 'setAvailableSlots',
  SET_TOTAL_PAGES_COMING = 'setTotalPagesComing',
  SET_TOTAL_PAGES_COMPLETED = 'setTotalPagesCompleted',
  SET_DELETE_APPOINTMENT_ID = 'setDeleteAppointmentId',
}

export type Action =
  | { type: ActionType.SET_COPIED, copied: boolean }
  | { type: ActionType.SET_SERVICE_ID, serviceId: string }
  | { type: ActionType.SET_PROVIDER_ID, providerId: string }
  | { type: ActionType.SET_TAB_VALUE, tabValue: string }
  | { type: ActionType.SET_PAGE_COMING; pageComing: number }
  | { type: ActionType.SET_OPEN_DELETE; openDelete: boolean }
  | { type: ActionType.SET_COMING_SEARCH_QUERY; searchComingQuery: string }
  | { type: ActionType.SET_PAST_SEARCH_QUERY; searchPastQuery: string }
  | { type: ActionType.SET_DATE; date: MaterialUiPickersDate }
  | { type: ActionType.SET_CURRENT_DATE, currentDate: string }
  | { type: ActionType.SET_IS_INSURANCE; isInsurance: boolean }
  | { type: ActionType.SET_FACILITY_NAME; facilityName: string }
  | { type: ActionType.SET_FACILITY_ID; facilityId: string }
  | { type: ActionType.SET_APPOINTMENT_ID; appointmentId: string }
  | { type: ActionType.SET_PAGE_COMPLETED; pageCompleted: number }
  | { type: ActionType.SET_TOTAL_PAGES_COMING; totalPagesComing: number }
  | { type: ActionType.SET_FACILITY; facility: FacilityPayload['facility'] }
  | { type: ActionType.SET_TOTAL_PAGES_COMPLETED; totalPagesCompleted: number }
  | { type: ActionType.SET_DELETE_APPOINTMENT_ID; deleteAppointmentId: string }
  | { type: ActionType.SET_AVAILABLE_SLOTS, availableSlots: SlotsPayload['slots'] }
  | { type: ActionType.SET_UP_COMING; upComing: AppointmentsPayload['appointments'] }
  | { type: ActionType.SET_COMPLETED; completed: AppointmentsPayload['appointments'] }
  | { type: ActionType.SET_APPOINTMENT; appointment: AppointmentPayload['appointment'] }
  | { type: ActionType.SET_APPOINTMENTS; appointments: AppointmentsPayload['appointments'] }

export const appointmentReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_PAGE_COMPLETED:
      return {
        ...state,
        pageCompleted: action.pageCompleted
      }

    case ActionType.SET_PAGE_COMING:
      return {
        ...state,
        pageComing: action.pageComing
      }

    case ActionType.SET_COPIED:
      return {
        ...state,
        copied: action.copied
      }

    case ActionType.SET_CURRENT_DATE:
      return {
        ...state,
        currentDate: action.currentDate
      }

    case ActionType.SET_SERVICE_ID:
      return {
        ...state,
        serviceId: action.serviceId
      }

    case ActionType.SET_TAB_VALUE:
      return {
        ...state,
        tabValue: action.tabValue
      }
      
    case ActionType.SET_IS_INSURANCE:
      return {
        ...state,
        isInsurance: action.isInsurance
      }

    case ActionType.SET_FACILITY:
      return {
        ...state,
        facility: action.facility
      }

    case ActionType.SET_AVAILABLE_SLOTS:
      return {
        ...state,
        availableSlots: action.availableSlots
      }

    case ActionType.SET_PROVIDER_ID:
      return {
        ...state,
        providerId: action.providerId
      }

    case ActionType.SET_TOTAL_PAGES_COMING:
      return {
        ...state,
        totalPagesComing: action.totalPagesComing
      }

    case ActionType.SET_TOTAL_PAGES_COMPLETED:
      return {
        ...state,
        totalPagesCompleted: action.totalPagesCompleted
      }

    case ActionType.SET_APPOINTMENT_ID:
      return {
        ...state,
        appointmentId: action.appointmentId
      }

    case ActionType.SET_COMING_SEARCH_QUERY:
      return {
        ...state,
        searchComingQuery: action.searchComingQuery
      }

    case ActionType.SET_PAST_SEARCH_QUERY:
      return {
        ...state,
        searchPastQuery: action.searchPastQuery
      }

    case ActionType.SET_OPEN_DELETE:
      return {
        ...state,
        openDelete: action.openDelete
      }

    case ActionType.SET_APPOINTMENT:
      return {
        ...state,
        appointment: action.appointment
      }

    case ActionType.SET_APPOINTMENTS:
      return {
        ...state,
        appointments: action.appointments
      }

    case ActionType.SET_COMPLETED:
      return {
        ...state,
        completed: action.completed
      }

    case ActionType.SET_UP_COMING:
      return {
        ...state,
        upComing: action.upComing
      }

    case ActionType.SET_DELETE_APPOINTMENT_ID:
      return {
        ...state,
        deleteAppointmentId: action.deleteAppointmentId
      }

    case ActionType.SET_DATE:
      return {
        ...state,
        date: action.date
      }

    case ActionType.SET_FACILITY_NAME:
      return {
        ...state,
        facilityName: action.facilityName
      }

    case ActionType.SET_FACILITY_ID:
      return {
        ...state,
        facilityId: action.facilityId
      }
  }
};
