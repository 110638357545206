// packages block
import { Box, Grid, Typography } from "@material-ui/core";
// constants, history, styling block
import { WHITE } from "../../../theme";
import { useWelcomeStyles } from "../../../styles/welcomeStyles";
import { SYMPTOMS, SYMPTOMS_DATA } from "../../../constants";

const SymptomsComponent = (): JSX.Element => {
  const classes = useWelcomeStyles();

  return (
    <Box bgcolor={WHITE} py={3}>
      <Box className={classes.containerBox}>
        <Grid container spacing={4} alignItems="center">
          <Grid item md={6} sm={12} xs={12}>
            <Box mb={3} className={classes.bigBorder} />

            <Typography variant="h4" className={classes.title}>{SYMPTOMS}</Typography>

            <Box p={4} />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          {SYMPTOMS_DATA.map((item, index) => {
            return (
              <Grid item key={index} md={3} sm={6} xs={6}>
                <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                  <Box mb={2} className={classes.iconBoxSymptoms}>
                    <Box>
                      {item.icon}
                    </Box>
                  </Box>
                  
                  <Typography variant="body1">{item.title}</Typography>
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </Box>
  )
}

export default SymptomsComponent;