// packages block
import { ReactNode } from "react";
import moment from "moment";
import DOMPurify from "dompurify";
import { Collection, sortBy } from "underscore";
import { Typography, Box, TableCell, InputLabel } from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
// graphql, constants, history, apollo, interfaces/types and constants block
import client from "../apollo";
import history from "../history";
import { BLUE_FIVE, RED_ONE, RED, GREEN } from "../theme";
import { Order, SelectorOption, TableAlignType } from "../interfacesTypes";
import {
  FacilitiesPayload, ServicesPayload, AppointmentsPayload, AttachmentsPayload, AllDoctorPayload,
  Maybe, AttachmentType, AppointmentStatus, SlotsPayload, Service, Doctor,
} from "../generated/graphql"
import {
  CLAIMS_ROUTE, DASHBOARD_ROUTE, INITIATED, INVOICES_ROUTE, LAB_RESULTS_ROUTE, TOKEN,
  USER_EMAIL, VIEW_APPOINTMENTS_ROUTE, CANCELLED, ATTACHMENT_TITLES, N_A, CARE_TEAM_ROUTE, HOME_ROUTE,
  SYSTEM_ROLES, ACCEPTABLE_FILES, ACCEPTABLE_ONLY_IMAGES_FILES, ACCEPTABLE_PDF_AND_IMAGES_FILES, ASC,
  CANCEL_TIME_EXPIRED_MESSAGE,
  ITEM_MODULE,
} from "../constants";
import { Skeleton } from "@material-ui/lab";

export const handleLogout = () => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(USER_EMAIL);
  history.push(HOME_ROUTE);
  client.clearStore();
};

export const upperToNormal = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

export const formatServiceCode = (value: string) => {
  const parts = value.split("_");
  let formatted = `${parts[parts.length - 1]} - `;

  for (let index in parts) {
    if (parseInt(index) < parts.length - 1) {
      formatted = `${formatted} ${parts[parseInt(index)].charAt(0)}${parts[parseInt(index)].slice(1).toLowerCase()} `
    }
  }

  return formatted;
};

export const formatValue = (value: string) => {
  let formatted = ''

  value.split("_").map(term =>
    formatted = `${formatted} ${term.charAt(0).toUpperCase()}${term.slice(1).toLowerCase()}`)

  return formatted.trim();
};

export const renderItem = (
  name: string,
  value: Maybe<string> | number | ReactNode | undefined,
  noWrap?: boolean,
) => (
  <>
    <Typography variant="body2">{name}</Typography>
    <Typography component="h5" variant="h5" noWrap>
      {value ? value : N_A}
    </Typography>
  </>
);

export const renderTh = (text: string, align?: TableAlignType) => (
  <TableCell component="th" align={align}>
    <Typography component="h5" variant="h5">
      {text}
    </Typography>
  </TableCell>
);

export const requiredLabel = (label: string) => {
  return (
    <Box>
      {label}
      <Box component="span" color="red">
        {' '}
        *
      </Box>
    </Box>
  )
}

export const recordNotFound = (record: string = "Record"): string => {
  return `${record} not found.`
};

export const getToken = () => {
  return localStorage.getItem(TOKEN);
};

export const requiredMessage = (fieldName: string) => `${fieldName} is required`;
export const invalidMessage = (fieldName: string) => `${fieldName} is invalid`;
export const tooShort = (fieldName: string) => `${fieldName} is too short`;
export const tooLong = (fieldName: string) => `${fieldName} is too long`;

export const getTimestamps = (date: string): string => {
  return date ? moment(date).format().toString() : moment().format().toString()
};

export const getCurrentTimestamps = (existingDate: string, newDate: MaterialUiPickersDate) => {
  const currentDate = moment(newDate).format(`MM/DD/YYYY`)
  const existingTime = moment(existingDate).format(`hh:mm A`)
  const newDateTime = currentDate + ' ' + existingTime
  return newDateTime ? moment(newDateTime).format().toString() : moment().format().toString()
};

export const getDate = (date: string) => {
  return moment(date, "x").format("YYYY-MM-DD")
};

export const getFormattedDate = (date: string) => {
  if (date) {
    const parsedDate = parseInt(date)
    const formatDate = new Date(parsedDate)

    return formatDate;
  }

  return ''
};

export const getAppointmentDateTime = (date: string) => {
  const timeDate = moment(date, "x")

  return `${timeDate.format("ddd MMM. DD, YYYY")} at ${timeDate.format("hh:mm A")}`
};

export const deleteRecordTitle = (recordType: string) => {
  return `Delete ${recordType} Record`;
}

export const cancelRecordTitle = (recordType: string) => {
  return `Cancel ${recordType} Record`;
}

export const aboutToDelete = (recordType: string) => {
  return `You are about to delete ${recordType.toLowerCase()} record`;
}

export const aboutToCancel = (recordType: string) => {
  return `You are about to cancel ${recordType.toLowerCase()} record`;
}


export const renderFacilities = (facilities: FacilitiesPayload['facilities']) => {
  const data: SelectorOption[] = [];

  if (!!facilities) {
    for (let facility of facilities) {
      if (facility) {
        const { id, name } = facility;

        data.push({ id, name })
      }
    }
  }

  return data;
}

export const renderDoctors = (doctors: AllDoctorPayload['doctors']) => {
  const data: SelectorOption[] = [];

  if (!!doctors) {
    for (let doctor of doctors) {
      if (doctor) {
        const { id, firstName, lastName } = doctor;
        data.push({ id, name: `${firstName} ${lastName}` })
      }
    }
  }

  return data;
}

export const renderServices = (services: ServicesPayload['services']) => {
  const data: SelectorOption[] = [];

  if (!!services) {
    for (let service of services) {
      if (service) {
        const { id, name, duration } = service;

        data.push({ id, name: `${name.trim()} (duration: ${duration} minutes)` })
      }
    }
  }

  return data;
}

export const setRecord = (id: string, name: string, format = true): SelectorOption => {
  let value = ''
  if (name) {
    value = format ? formatValue(name) : name
  }

  return { id, name: value };
};

export const formatPhone = (phone: string): string => {
  return (phone && phone) ? `(${phone.substring(0, 3)})  ${phone.substring(3, 6)}-${phone.substring(6, 11)}` : ''
};

export const dateValidation = (endDate: string, startDate: string): boolean => {
  if (startDate && endDate) {
    return new Date(endDate) >= new Date(startDate)
  } else return true;
};

export const dateValidationMessage = (endDateName: string, startDateName: string): string => {
  return `${endDateName} should be greater than ${startDateName}`
};

export const getTimeFromTimestamps = (timestamp: string) => {
  if (!timestamp) return "";

  return new Date(parseInt(timestamp)).toISOString()
};

export const getISOTime = (timestamp: string) => {
  if (!timestamp) return "";

  return new Date(parseInt(timestamp)).toISOString()
};

export const getStandardTime = (timestamp: string) => {
  if (!timestamp) return "";

  return new Date(parseInt(timestamp)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
};

export const getDayFromTimestamps = (timestamp: string) => {
  if (!timestamp) return "";

  return new Date(parseInt(timestamp)).toLocaleString('en-us', { weekday: 'long' })
}

export const activeClass = (pathname: string): string => {
  switch (pathname) {
    case DASHBOARD_ROUTE:
      return 'inDashboard';

    case VIEW_APPOINTMENTS_ROUTE:
      return "inAppointment"

    case LAB_RESULTS_ROUTE:
      return "inReport"

    case CARE_TEAM_ROUTE:
      return "inCareTeam"

    case INVOICES_ROUTE:
    case CLAIMS_ROUTE:
      return "inBilling"

    default:
      return ''
  }
};

const makeTodayAppointment = (startDate: Date, endDate: Date) => {
  const currentDate = moment(startDate);

  const days = moment(startDate).diff(endDate, 'days');
  const nextStartDate = moment(startDate)
    .year(currentDate.year())
    .month(currentDate.month())
    .date(parseInt(startDate.toDateString()));

  const nextEndDate = moment(endDate)
    .year(currentDate.year())
    .month(currentDate.month())
    .date(parseInt((endDate).toDateString()) + days);

  return {
    startDate: nextStartDate.toDate(),
    endDate: nextEndDate.toDate(),
  };
};

export const mapAppointmentData = (data: AppointmentsPayload['appointments']) => {
  return data?.map(appointment => {
    const { scheduleEndDateTime, scheduleStartDateTime, patient, id, appointmentType } = appointment || {}
    const { firstName, lastName } = patient || {}
    const { color } = appointmentType || {}

    return {
      id, color,
      title: `${firstName} ${lastName}`,
      ...makeTodayAppointment(new Date(parseInt(scheduleStartDateTime || '')), new Date(parseInt(scheduleEndDateTime || '')))
    }

  })
}

export const appointmentStatus = (status: string) => {
  const cancelled = status === AppointmentStatus.Cancelled;

  return {
    text: cancelled ? CANCELLED : INITIATED,
    bgColor: cancelled ? BLUE_FIVE : RED_ONE,
    textColor: cancelled ? RED : GREEN
  }
};

export const getDocumentByType = (attachmentData: AttachmentsPayload['attachments']) => {
  const drivingLicense1 = attachmentData?.filter(attachment => attachment?.title === ATTACHMENT_TITLES.DrivingLicense1)[0] || undefined
  const drivingLicense2 = attachmentData?.filter(attachment => attachment?.title === ATTACHMENT_TITLES.DrivingLicense2)[0] || undefined
  const insuranceCard1 = attachmentData?.filter(attachment => attachment?.title === ATTACHMENT_TITLES.InsuranceCard1)[0] || undefined
  const insuranceCard2 = attachmentData?.filter(attachment => attachment?.title === ATTACHMENT_TITLES.InsuranceCard2)[0] || undefined

  return {
    drivingLicense1, drivingLicense2, insuranceCard1, insuranceCard2
  }
};

export const sanitizedData = (data: string) => ({
  __html: DOMPurify.sanitize(data)
})

export const getProfileImageType = (userType: string) => {

  if (userType === SYSTEM_ROLES.SuperAdmin) {
    return AttachmentType.SuperAdmin
  }

  else if (userType === SYSTEM_ROLES.Doctor) {
    return AttachmentType.Doctor
  }

  else if (userType === SYSTEM_ROLES.Patient) {
    return AttachmentType.Patient
  }

  else {
    return AttachmentType.Staff
  }
}

export const getStandardTimeByMoment = (timestamp: string) => {
  if (!timestamp) return "";
  const date = new Date(parseInt(timestamp)).setDate((new Date().getDate()) - 1)
  const parsedDate = new Date(date).toISOString()
  const newDate = moment(parsedDate).local().toString()
  const d = moment(newDate).format()
  return d
};

const isToday = (someDate: Date) => {
  const today = new Date()

  return someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
}

const isTimePassed = (time: string) => new Date() < new Date(time);

export const filterSlots = (slots: SlotsPayload['slots'], date: string | MaterialUiPickersDate) => {
  let filteredSlots: SlotsPayload['slots'] = []

  if (date && isToday(new Date(date.toString()))) {
    filteredSlots = slots?.filter(slot => {
      const { startTime } = slot || {}

      return startTime && isTimePassed(startTime)
    })

    return filteredSlots;
  }

  return slots
}

export const getScheduleStartTime = (time: string) => {
  if (!time) return "";

  return new Date(new Date(time).getTime()).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }).toString()
}

export const mediaType = (attachmentTitle: string): string[] => {
  switch (attachmentTitle) {
    case ATTACHMENT_TITLES.DrivingLicense1:
      return ACCEPTABLE_PDF_AND_IMAGES_FILES;

    case ATTACHMENT_TITLES.DrivingLicense2:
      return ACCEPTABLE_PDF_AND_IMAGES_FILES;

    case ATTACHMENT_TITLES.InsuranceCard1:
      return ACCEPTABLE_ONLY_IMAGES_FILES;

    case ATTACHMENT_TITLES.InsuranceCard2:
      return ACCEPTABLE_ONLY_IMAGES_FILES;

    case ATTACHMENT_TITLES.ProfilePicture:
      return ACCEPTABLE_ONLY_IMAGES_FILES;

    default:
      return ACCEPTABLE_FILES
  }
};

export const convertDateFromUnix = (date: Maybe<string> | undefined, format = "MM-DD-YYYY") => {
  return !date ? '' : moment(date, 'x').format(format).toString()
};

export const canCancelAppointment = (status: AppointmentStatus, time: string) => {
  return moment(getISOTime(time || '')).diff(moment(), 'hours') <= 1
    ? CANCEL_TIME_EXPIRED_MESSAGE : status !== AppointmentStatus.Scheduled
      ? `Appointment with status "${formatValue(status || '')}" can't be cancelled!` : ''
}

export function sortingArray<arrayType>(array: arrayType, by: string, order: Order): arrayType {
  const sorted = sortBy(array as Collection<any>, by)

  return (order === ASC ? sorted : sorted.reverse()) as unknown as arrayType
}

export function renderListOptions<ListOptionTypes>(list: ListOptionTypes[], modalName: ITEM_MODULE) {
  const data: SelectorOption[] = [];

  if (!!list) {
    for (let item of list) {
      switch (modalName) {
        case ITEM_MODULE.Services:
          const { id, name, duration } = (item as unknown as Service) || {};
          data.push({ id, name: `${name.trim()} (duration: ${duration} minutes)` })
          break;

        case ITEM_MODULE.Providers:
          const { id: providerId, firstName, lastName } = (item as unknown as Doctor) || {};
          data.push({ id: providerId, name: `${firstName} ${lastName}`.trim() })
          break;

        default:
          break;
      }
    }
  }

  return data;
};

export const renderLoading = (label: string | JSX.Element) => (
  <>
    <Box position="relative">
      <InputLabel shrink className="skelton-label-margin">
        {label}
      </InputLabel>
    </Box>

    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      borderRadius={4} className="skelton-input"
    >
      <Skeleton animation="pulse" variant="rect" width={1000} height={48} />
    </Box>
  </>
);
