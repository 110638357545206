// packages block
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import DateFnsUtils from '@date-io/date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Box, Button, CircularProgress, Grid } from "@material-ui/core";
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { useEffect, FC, useContext, Reducer, useReducer, useCallback } from 'react';
// components block
import Alert from "../../../common/Alert";
import PageHeader from '../../../common/PageHeader';
import InputController from '../../../../controller';
import ItemSelector from '../../../common/ItemSelector';
import CardComponent from "../../../common/CardComponent";
import ViewDataLoader from '../../../common/ViewDataLoader';
import NoSlotsComponent from '../../../common/NoSlotsComponent';
import ToggleButtonComponent from '../../../common/ToggleButtonComponent';
// interfaces, graphql, constants block
import history from "../../../../history";
import { AuthContext } from '../../../../context';
import { appointmentSchema } from '../../../../validationSchemas';
import { BLACK_FOUR, GRAY_ONE, WHITE, WHITE_FIVE } from '../../../../theme';
import { usePublicAppointmentStyles } from "../../../../styles/publicAppointmentStyles";
import { ExtendedAppointmentInputProps, GeneralFormProps } from "../../../../interfacesTypes";
import {
  appointmentReducer, Action, initialState, State, ActionType
} from '../../../../reducers/appointmentReducer';
import {
  getTimeFromTimestamps, setRecord, getStandardTime, getCurrentTimestamps,
  getStandardTimeByMoment, filterSlots, getScheduleStartTime,
} from "../../../../utils";
import {
  PaymentType, Slots, useCreateAppointmentMutation, useGetAppointmentLazyQuery, BillingStatus,
  useGetSlotsLazyQuery, useUpdateAppointmentMutation, SlotsPayload,
} from "../../../../generated/graphql";
import {
  APPOINTMENT_BOOKED_SUCCESSFULLY, APPOINTMENT_UPDATED_SUCCESSFULLY, SLOT_ALREADY_BOOKED,
  PROVIDER, EMPTY_OPTION, UPDATE_APPOINTMENT, CREATE_APPOINTMENT, CANT_BOOK_APPOINTMENT,
  APPOINTMENT_NOT_FOUND, CANT_UPDATE_APPOINTMENT, APPOINTMENT, APPOINTMENT_TYPE, REASON,
  SECONDARY_INSURANCE, PATIENT_CONDITION, EMPLOYMENT, AUTO_ACCIDENT, OTHER_ACCIDENT,
  APPOINTMENT_SLOT_ERROR_MESSAGE, DAYS, EDIT_APPOINTMENT, APPOINTMENT_EDIT_BREAD, NOTES,
  APPOINTMENTS_BREAD, APPOINTMENT_NEW_BREAD, PRIMARY_INSURANCE, CONFLICT_EXCEPTION,
  VIEW_APPOINTMENTS_BREAD, VIEW_APPOINTMENTS_ROUTE, ITEM_MODULE,
} from "../../../../constants";

const AppointmentForm: FC<GeneralFormProps> = ({ isEdit, id }) => {
  const classes = usePublicAppointmentStyles();
  const { patient, user } = useContext(AuthContext)
  const [state, dispatch] = useReducer<Reducer<State, Action>>(appointmentReducer, initialState)

  const methods = useForm<ExtendedAppointmentInputProps>({
    mode: "all", resolver: yupResolver(appointmentSchema)
  });
  const { id: patientId } = patient || {}
  const { facility } = user || {}

  const { id: facilityId, practiceId } = facility || {}
  const { availableSlots, serviceId, offset, currentDate, date } = state
  const { reset, setValue, handleSubmit, watch } = methods;

  const {
    serviceId: { id: selectedService } = {},
    providerId: { id: selectedProvider } = {},
    scheduleStartDateTime
  } = watch();
  const scheduleStartTime = getScheduleStartTime(scheduleStartDateTime)

  const [getAppointment, { loading: getAppointmentLoading }] = useGetAppointmentLazyQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onError({ message }) {
      Alert.error(message)
    },

    onCompleted(data) {
      const { getAppointment: { response, appointment } } = data;

      if (response) {
        const { status } = response

        if (appointment && status && status === 200) {
          const {
            reason, scheduleStartDateTime, scheduleEndDateTime, notes, primaryInsurance, secondaryInsurance,
            employment, autoAccident, otherAccident, appointmentType, provider
          } = appointment || {}

          const { id: serviceId, name: serviceName } = appointmentType || {};
          const { id: providerId, firstName: providerFN, lastName: providerLN } = provider || {};

          notes && setValue('notes', notes)
          reason && setValue('reason', reason)
          employment && setValue('employment', employment)
          autoAccident && setValue('autoAccident', autoAccident)
          otherAccident && setValue('otherAccident', otherAccident)
          primaryInsurance && setValue('primaryInsurance', primaryInsurance)
          secondaryInsurance && setValue('secondaryInsurance', secondaryInsurance)
          serviceId && serviceName && setValue('serviceId', setRecord(serviceId, serviceName))
          providerId && setValue('providerId', setRecord(providerId, `${providerFN} ${providerLN}` || '--'))
          scheduleEndDateTime && setValue('scheduleStartDateTime', getStandardTimeByMoment(scheduleEndDateTime || ''))
          scheduleStartDateTime && setValue('scheduleEndDateTime', getStandardTimeByMoment(scheduleStartDateTime || ''))

          dispatch({
            type: ActionType.SET_DATE,
            date: new Date(getTimeFromTimestamps(scheduleStartDateTime || '')) as MaterialUiPickersDate
          })
        }
      }
    }
  });

  const [getSlots, { loading: getSlotsLoading }] = useGetSlotsLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",

    onError() {
      dispatch({ type: ActionType.SET_AVAILABLE_SLOTS, availableSlots: [] })
    },

    onCompleted(data) {
      const { getSlots } = data || {}

      if (getSlots) {
        const { slots } = getSlots;

        if (slots) {
          dispatch({
            type: ActionType.SET_AVAILABLE_SLOTS,
            availableSlots: filterSlots(slots, date) as SlotsPayload['slots']
          });
        } else {
          dispatch({ type: ActionType.SET_AVAILABLE_SLOTS, availableSlots: [] });
        }
      }
    }
  });

  const [createAppointment, { loading: CreateAppointmentLoading }] = useCreateAppointmentMutation({
    onError({ message }) {
      if (message === CONFLICT_EXCEPTION) {
        Alert.error(SLOT_ALREADY_BOOKED)
      } else
        Alert.error(message || CANT_BOOK_APPOINTMENT)
    },

    onCompleted(data) {
      const { createAppointment: { response } } = data;

      if (response) {
        const { status } = response

        if (status && status === 200) {
          Alert.success(APPOINTMENT_BOOKED_SUCCESSFULLY);
          reset()
          history.push(VIEW_APPOINTMENTS_ROUTE)
        }
      }
    }
  });

  const [updateAppointment, { loading: updateAppointmentLoading }] = useUpdateAppointmentMutation({
    fetchPolicy: "network-only",

    onError({ message }) {
      Alert.error(message)
    },

    onCompleted(data) {
      const { updateAppointment: { response } } = data;

      if (response) {
        const { status } = response

        if (status && status === 200) {
          Alert.success(APPOINTMENT_UPDATED_SUCCESSFULLY);
          reset()
          history.push(VIEW_APPOINTMENTS_ROUTE)
        }
      }
    }
  });

  const fetchAppointment = useCallback(async () => {
    id && await getAppointment({
      variables: { getAppointment: { id } }
    })
  }, [getAppointment, id])

  useEffect(() => {
    if (isEdit) {
      id ? fetchAppointment() : Alert.error(APPOINTMENT_NOT_FOUND)
    } else {
      setValue('employment', false)
      setValue('autoAccident', false)
      setValue('otherAccident', false)
      setValue('providerId', setRecord('', '--'))
    }
  }, [fetchAppointment, id, isEdit, setValue])

  useEffect(() => {
    if (selectedService && date) {
      const days = [DAYS.Sunday, DAYS.Monday, DAYS.Tuesday, DAYS.Wednesday, DAYS.Thursday, DAYS.Friday, DAYS.Saturday];
      const currentDay = new Date(date).getDay()
      const slotsInput = { offset, currentDate: date.toString(), serviceId: selectedService, day: days[currentDay] };

      getSlots({
        variables: {
          getSlots: selectedProvider ? { providerId: selectedProvider, ...slotsInput } : { facilityId, ...slotsInput }
        }
      })
    }
  }, [currentDate, offset, date, selectedService, serviceId, watch, getSlots, selectedProvider, facilityId])

  useEffect(() => {
    setValue('scheduleEndDateTime', '')
    setValue('scheduleStartDateTime', '')
  }, [date, selectedService, setValue, selectedProvider])

  const onSubmit: SubmitHandler<ExtendedAppointmentInputProps> = async (inputs) => {
    const {
      reason, scheduleStartDateTime, scheduleEndDateTime, notes, primaryInsurance,
      secondaryInsurance, employment, autoAccident, otherAccident, serviceId, providerId
    } = inputs;

    if (!scheduleStartDateTime || !scheduleEndDateTime) {
      Alert.error(APPOINTMENT_SLOT_ERROR_MESSAGE)
    } else {
      const { id: selectedService } = serviceId || {};
      const { id: selectedProvider } = providerId || {};

      const appointmentInput = {
        reason: reason || '', notes: notes || '', otherAccident: otherAccident || false,
        scheduleEndDateTime: getCurrentTimestamps(scheduleEndDateTime, date), practiceId,
        scheduleStartDateTime: getCurrentTimestamps(scheduleStartDateTime, date),
        primaryInsurance: primaryInsurance || '', secondaryInsurance: secondaryInsurance || '',
        autoAccident: autoAccident || false, paymentType: PaymentType.Self, patientId, facilityId,
        employment: employment || false, billingStatus: BillingStatus.Due,
        appointmentDate: moment(date).format('YYYY-MM-DD'),
        timeZone: momentTimezone.tz.guess()
      };

      const payload = selectedProvider ? { ...appointmentInput, providerId: selectedProvider } : { ...appointmentInput }

      if (isEdit) {
        id ?
          await updateAppointment({
            variables: { updateAppointmentInput: { id, ...payload } }
          })
          : Alert.error(CANT_UPDATE_APPOINTMENT)
      } else {
        await createAppointment({
          variables: { createAppointmentInput: { ...payload, appointmentTypeId: selectedService } }
        })
      }
    }
  };

  const handleSlot = (slot: Slots) => {
    if (slot) {
      const { startTime, endTime } = slot;
      endTime && setValue('scheduleEndDateTime', endTime)
      startTime && setValue('scheduleStartDateTime', startTime)
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>

        <Box display="flex" alignItems="center" justifyContent="space-between" mt={1}>
          <PageHeader
            title={!isEdit ? CREATE_APPOINTMENT : EDIT_APPOINTMENT}
            path={!isEdit ? [APPOINTMENTS_BREAD, VIEW_APPOINTMENTS_BREAD, APPOINTMENT_NEW_BREAD] :
              [APPOINTMENTS_BREAD, VIEW_APPOINTMENTS_BREAD, APPOINTMENT_EDIT_BREAD]
            }
          />

          <Box display="flex" justifyContent="flex-end" pb={2.25}>
            <Button type="submit" variant="contained" color="primary"
              disabled={updateAppointmentLoading || CreateAppointmentLoading}
            >
              {isEdit ? UPDATE_APPOINTMENT : CREATE_APPOINTMENT}

              {(updateAppointmentLoading || CreateAppointmentLoading) &&
                <CircularProgress size={20} color="inherit" />
              }
            </Button>
          </Box>
        </Box>

        <Box maxHeight="calc(100vh - 248px)" className="overflowY-auto">
          <Grid container spacing={3}>
            {!isEdit &&
              <Grid md={6} item>
                <CardComponent cardTitle={APPOINTMENT}>
                  {getAppointmentLoading ? <ViewDataLoader rows={5} columns={6} hasMedia={false} /> : (
                    <>
                      <Grid container spacing={3}>
                        <Grid item md={6} sm={12} xs={12}>
                          <ItemSelector
                            addEmpty
                            label={PROVIDER}
                            name="providerId"
                            value={EMPTY_OPTION}
                            modalName={ITEM_MODULE.Providers}
                          />
                        </Grid>

                        <Grid item md={6} sm={12} xs={12}>
                          <ItemSelector
                            isRequired
                            name="serviceId"
                            value={EMPTY_OPTION}
                            label={APPOINTMENT_TYPE}
                            modalName={ITEM_MODULE.Services}
                          />
                        </Grid>
                      </Grid>

                      <InputController
                        fieldType="text"
                        controllerName="reason"
                        controllerLabel={REASON}
                      />

                      <InputController
                        fieldType="text"
                        controllerName="notes"
                        controllerLabel={NOTES}
                      />

                      <InputController
                        fieldType="text"
                        controllerName="primaryInsurance"
                        controllerLabel={PRIMARY_INSURANCE}
                      />

                      <InputController
                        fieldType="text"
                        controllerName="secondaryInsurance"
                        controllerLabel={SECONDARY_INSURANCE}
                      />
                    </>
                  )}
                </CardComponent>
              </Grid>
            }

            <Grid md={6} item>
              <Grid item md={12} sm={12} className="custom-calendar">
                <CardComponent cardTitle="Available Slots">
                  <Box display="flex" justifyContent="center">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        autoOk
                        fullWidth
                        disablePast
                        disableToolbar
                        value={date}
                        openTo="date"
                        variant="static"
                        onChange={currentDate => currentDate &&
                          dispatch({ type: ActionType.SET_DATE, date: currentDate })
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </Box>

                  {getSlotsLoading ? <ViewDataLoader rows={3} columns={6} hasMedia={false} /> : (
                    <ul className={classes.timeSlots}>
                      {!!availableSlots?.length ? availableSlots.map((slot: Slots, index: number) => {
                        const { startTime, endTime } = slot || {}
                        const startDateTime = getStandardTime(new Date(startTime || '').getTime().toString())

                        return (
                          <li key={index}>
                            <Box py={1.375} textAlign={'center'} border={`1px solid ${GRAY_ONE}`} borderRadius={6}
                              bgcolor={startDateTime === scheduleStartTime ? WHITE_FIVE : WHITE}
                              color={startDateTime === scheduleStartTime ? WHITE : BLACK_FOUR}
                              className={classes.timeSlot}
                              onClick={() => handleSlot(slot)}
                            >
                              {getStandardTime(new Date(startTime || '').getTime().toString())} -{' '}
                              {getStandardTime(new Date(endTime || '').getTime().toString())}
                            </Box>
                          </li>
                        )
                      }) : (
                        <NoSlotsComponent />
                      )}
                    </ul>
                  )}
                </CardComponent>
              </Grid>

              <Box pb={3} />

              {!isEdit &&
                <CardComponent cardTitle={PATIENT_CONDITION}>
                  {getAppointmentLoading ? <ViewDataLoader rows={5} columns={6} hasMedia={false} /> : (
                    <>
                      <Grid container spacing={3}>
                        <Grid item md={6} sm={12} xs={12}>
                          <ToggleButtonComponent name="employment" label={EMPLOYMENT} />
                        </Grid>

                        <Grid item md={6} sm={12} xs={12}>
                          <ToggleButtonComponent name="autoAccident" label={AUTO_ACCIDENT} />
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item md={6} sm={12} xs={12}>
                          <ToggleButtonComponent name="otherAccident" label={OTHER_ACCIDENT} />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </CardComponent>
              }
            </Grid>
          </Grid>
        </Box>
      </form>
    </FormProvider>
  );
};

export default AppointmentForm;
