// packages block
import { Link } from "react-router-dom";
import { Box, Grid, Typography } from "@material-ui/core";
import { AIMED_COPYRIGHT_TEXT, FOOTER_ITEMS, } from "../../../constants";
// constants, history, styling block
import { BLACK_SEVEN } from "../../../theme";
import { useWelcomeStyles } from "../../../styles/welcomeStyles";

const FooterComponent = (): JSX.Element => {
  const classes = useWelcomeStyles();

  return (
    <Box bgcolor={BLACK_SEVEN} p={3}>
      <Grid container spacing={0} justifyContent="center">
        <Grid item md={6} sm={12} xs={12}>
          <Box display="flex" alignItems="center" justifyContent="space-evenly" flexWrap="wrap">
            {FOOTER_ITEMS.map((item, index) => {
              return (
                <Typography variant="h6" component={Link} to={item.link} key={index} className={classes.footerItem}>{item.name}</Typography>
              )
            })}

            <Typography variant="h6" className={classes.footerCopy}>{AIMED_COPYRIGHT_TEXT}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default FooterComponent;