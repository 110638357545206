// packages block
import { FC, Fragment, useState } from "react";
import { Box, ClickAwayListener, IconButton, TextField, Typography } from "@material-ui/core";
// styles, constants, utils and interfaces block
import { useTableStyles } from "../../styles/tableStyles";
import { SearchTooltip } from "../../styles/searchTooltip";
import { SearchComponentProps } from "../../interfacesTypes";
import { SearchIcon, ClearIcon, InfoSearchIcon } from "../../assets/svgs";

const Search: FC<SearchComponentProps> = ({ search, info, tooltipData, placeHolder }): JSX.Element => {
  const classes = useTableStyles()
  const [query, setQuery] = useState<string>('')
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => setOpen(false);
  const handleTooltipOpen = () => setOpen(true);

  const handleClear = () => {
    if (query.length > 2) {
      setQuery('')
      search('')
    } else setQuery('')
  }

  return (
    <Box className={classes.searchBox}>
      <IconButton aria-label="search">
        <SearchIcon />
      </IconButton>

      <TextField
        fullWidth
        variant="outlined"
        name="searchQuery"
        placeholder={placeHolder ? placeHolder : "Search here..."}
        className={classes.searchInput}
        value={query}
        onChange={({ target: { value } }) => {
          if (value.length > 2) {
            search(value)
            setQuery(value)
          }

          if (!value.length) {
            search('')
          }

          setQuery(value)
        }}
      />

      {query &&
        <IconButton type="submit" aria-label="clear" onClick={handleClear}>
          <ClearIcon />
        </IconButton>
      }
      {info && <ClickAwayListener onClickAway={handleTooltipClose}>
        <SearchTooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          arrow
          placement="right"
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          className={classes.tooltipContainer}
          title={
            <Fragment>
              {tooltipData?.map((item) => {
                return (
                  <Box display='flex' justifyContent='space-between'>
                    <Typography variant="h6">{item.name}</Typography>
                    <Typography variant="caption">{item.format}</Typography>
                  </Box>
                )
              })}
            </Fragment>
          }
        >
          <Box onClick={handleTooltipOpen} pr={2}>
            <InfoSearchIcon />
          </Box>
        </SearchTooltip>
      </ClickAwayListener>}
    </Box>
  );
};

export default Search;
