// packages block
import { Link } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@material-ui/core";
// constants, history, styling block
import { ChevronRight } from "@material-ui/icons";
import { GRAY_TEN, WHITE } from "../../../theme";
import { useWelcomeStyles } from "../../../styles/welcomeStyles";
import { DentalIcon, EmergencyIcon, QuickIcon } from "../../../assets/svgs";
import {
  ACCESS_PORTAL, BEST_DENTAL, CONTROL_DESCRIPTION, CONTROL_TITLE, EMERGENCY_CASE, LOGIN_ROUTE, QUICK_EXAMINATION, QUICK_EXAMINATION_DESCRIPTION
} from "../../../constants";

const ControlComponent = (): JSX.Element => {
  const classes = useWelcomeStyles();

  return (
    <Box bgcolor={WHITE} py={3}>
      <Box className={classes.containerBox}>
        <Grid container spacing={4} alignItems="center">
          <Grid item md={6} sm={12} xs={12}>
            <Box px={3}>
              <Box mb={3} className={classes.bigBorder}></Box>

              <Typography variant="h4" className={classes.title}>{CONTROL_TITLE}</Typography>

              <Box p={4} />

              <Typography variant="body1" className={classes.description}>{CONTROL_DESCRIPTION}</Typography>

              <Box p={4} />

              <Button variant="text" component={Link} to={LOGIN_ROUTE} color="inherit" className="blue-button-New">
                {ACCESS_PORTAL}
                <ChevronRight />
              </Button>
            </Box>
          </Grid>

          <Grid item md={6} sm={12} xs={12} alignItems="center">
            <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
              <Box>
                <Box className={classes.outerBox}>
                  <Box mb={3} className={classes.iconBox}>
                    <QuickIcon />
                  </Box>

                  <Typography variant="h6"><strong>{QUICK_EXAMINATION}</strong></Typography>

                  <Box my={2} className={classes.smallBorder}></Box>

                  <Box maxWidth={130} color={GRAY_TEN}>
                    <Typography variant="body1">{QUICK_EXAMINATION_DESCRIPTION}</Typography>
                  </Box>
                </Box>

                <Box p={0.2} />

                <Box className={classes.outerBox}>
                  <Box mb={3} className={classes.iconBox}>
                    <DentalIcon />
                  </Box>

                  <Typography variant="h6"><strong>{BEST_DENTAL}</strong></Typography>

                  <Box my={2} className={classes.smallBorder}></Box>

                  <Box maxWidth={130} color={GRAY_TEN}>
                    <Typography variant="body1">{QUICK_EXAMINATION_DESCRIPTION}</Typography>
                  </Box>
                </Box>
              </Box>

              <Box>
                <Box className={classes.outerBox}>
                  <Box mb={3} className={classes.iconBox}>
                    <EmergencyIcon />
                  </Box>

                  <Typography variant="h6"><strong>{EMERGENCY_CASE}</strong></Typography>

                  <Box my={2} className={classes.smallBorder}></Box>

                  <Box maxWidth={130} color={GRAY_TEN}>
                    <Typography variant="body1">{QUICK_EXAMINATION_DESCRIPTION}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default ControlComponent;