// packages block
import { Box, Grid, } from "@material-ui/core";
// utils, styles and constants block
import { WHITE, } from "../../../theme";
import { useWelcomeStyles } from "../../../styles/welcomeStyles";

const CasesComponent = (): JSX.Element => {
  const classes = useWelcomeStyles();

  return (
    <Box bgcolor={WHITE}>
      <Box className={classes.containerBox}>
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          <Grid item md={7} sm={12} xs={12}>
              <iframe title="cases" src="https://public.domo.com/cards/axpDJ" width="100%" height="600" marginHeight={0} marginWidth={0} frameBorder={0}></iframe>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default CasesComponent;