// packages block
import { FC } from 'react';
// component block
import AppointmentForm from "../appointmentForm";
// constants block

import { useParams } from 'react-router';
import { ParamsType } from '../../../../interfacesTypes';

const VIewAppointmentComponent: FC = () => {
  const { id } = useParams<ParamsType>();

  return <AppointmentForm isEdit id={id} />
}

export default VIewAppointmentComponent;
