// packages block
import { Link } from "react-router-dom";
import { AppBar, Typography, Box, Toolbar, Button } from '@material-ui/core';
// utils and header styles block
import history from "../../../history";
import { AIMEDLOGO } from "../../../assets/svgs";
import { useHeaderStyles } from "../../../styles/headerStyles";
import { HEADER_ITEMS, LOGIN_ROUTE, LOGIN_TEXT, HOME_ROUTE, } from "../../../constants";

const HeaderComponent = (): JSX.Element => {
  const classes = useHeaderStyles();
  const { pathname } = history.location
  
  return (
    <AppBar className={classes.appBar}>
      <Box width="86%" margin="auto">
        <Toolbar className={classes.toolBar}>
          <Link to={HOME_ROUTE}>
            <AIMEDLOGO />
          </Link>

          <Box className={classes.menuBar}>
            {HEADER_ITEMS.map((item, index) => {
              return (
                <Typography component={Link} to={item.link} key={index} className={classes.menuItem}>{item.name}</Typography>
              )
            })}
          </Box>

          <Box>
            {pathname !== LOGIN_ROUTE &&
              <Button variant="contained" color="primary" component={Link} to={LOGIN_ROUTE}>{LOGIN_TEXT}</Button>
            }
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  )
}

export default HeaderComponent;
