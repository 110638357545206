// packages block
import { FC, useState, ChangeEvent } from "react";
import {
  CardContent, Button, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress,
  PropTypes, FormControlLabel, Checkbox, Typography, Box
} from "@material-ui/core";
// interfaces/types block/theme/svgs/constants
import { DeleteWarningIcon } from "../../assets/svgs";
import { ConfirmationTypes } from "../../interfacesTypes";
import { aboutToCancel, aboutToDelete, cancelRecordTitle, deleteRecordTitle } from "../../utils";
import { DELETE_RECORD, DELETE_RECORD_LEARN_MORE_TEXT, CANCEL, APPOINTMENT, CANCEL_APPOINTMENT_TEXT, CANCEL_RECORD } from "../../constants";

const ConfirmationModal: FC<ConfirmationTypes> = ({
  setOpen, isOpen, title, description, handleDelete, isLoading, actionText, success
}): JSX.Element => {
  const [checked, setChecked] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleClose = () => {
    setChecked(false)
    setOpen && setOpen(!isOpen)
  }

  const onDelete = () => {
    setChecked(false)
    handleDelete()
  }

  const buttonColor: PropTypes.Color = success ? "primary" : "secondary"

  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth="sm" fullWidth>
      <DialogTitle id="alert-dialog-title">
        {title === APPOINTMENT ? cancelRecordTitle(title || '') : deleteRecordTitle(title || '')}
      </DialogTitle>

      <DialogContent>
        <Box display="flex">
          <Box width={40} pt={0.75}>
            <DeleteWarningIcon />
          </Box>

          <CardContent>
            <Typography component="h4" variant="h5">
              {title === APPOINTMENT ? aboutToCancel(title || '') : aboutToDelete(title || '')}
            </Typography>

            {/* <Typography> */}
            {title === APPOINTMENT ? CANCEL_APPOINTMENT_TEXT : DELETE_RECORD_LEARN_MORE_TEXT}
            {/* </Typography> */}
          </CardContent>
        </Box>
      </DialogContent>

      <Box display="flex" ml={4} pb={2}>
        <FormControlLabel
          control={<Checkbox color="primary" checked={checked} onChange={handleChange} />}
          label={description}
        />
      </Box>


      <DialogActions>
        <Button onClick={handleClose} color="inherit" variant="text">
          {CANCEL}
        </Button>

        <Button onClick={onDelete} disabled={!checked || isLoading} variant="outlined" className="danger">
          {isLoading && <CircularProgress size={20} color={buttonColor} />}

          {actionText ? actionText : title === APPOINTMENT ? CANCEL_RECORD : DELETE_RECORD}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
