// packages block
import { Box, Grid, Typography } from "@material-ui/core";
// constants, history, styling block
import { GRAY_SIX,} from "../../../theme";
import { useWelcomeStyles } from "../../../styles/welcomeStyles";
import { PREVENTION, PREVENTION_DATA } from "../../../constants";

const PreventionComponent = (): JSX.Element => {
  const classes = useWelcomeStyles();

  return (
    <Box bgcolor={GRAY_SIX} py={3}>
      <Box className={classes.containerBox}>
        <Grid container spacing={4} alignItems="center">
          <Grid item md={6} sm={12} xs={12}>
            <Box mb={3} className={classes.bigBorder} />

            <Typography variant="h4" className={classes.title}>{PREVENTION}</Typography>
            
            <Box p={4} />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          {PREVENTION_DATA.map((item, index) => {
            return (
              <Grid item key={index} md={4} sm={6} xs={6}>
                <Box mb={5} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                  <Box>
                    {item.icon}
                  </Box>

                  <Box maxWidth={240} minHeight={50} mt={2} margin="auto" textAlign="center">
                    <Typography variant="body2">{item.description}</Typography>
                  </Box>
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </Box>
  )
}

export default PreventionComponent;