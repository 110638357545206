// packages block
import { FC, MouseEvent, useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
  Typography, Menu, MenuItem, Fade, IconButton, Avatar, Box, Grid, Button
} from '@material-ui/core';
// utils and header styles block
import { WHITE_FOUR } from "../../theme";
import { AuthContext } from "../../context";
import { DropDownItems } from "../../interfacesTypes";
import { useHeaderStyles } from "../../styles/headerStyles";
import { DownArrowIcon, MenuSettingIcon, MenuShieldIcon } from "../../assets/svgs";
import {
  GENERAL, GENERAL_MENU_ITEMS, LOGOUT_TEXT, SECURITY, SECURITY_MENU_ITEMS
} from "../../constants";

const DropdownMenu: FC<DropDownItems> = ({ itemName, menuItem, avatarIcon, current }): JSX.Element => {
  const classes = useHeaderStyles();
  const {  profileUrl, currentUser, logoutUser } = useContext(AuthContext);
  const { firstName, lastName } = currentUser || {}

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      {!avatarIcon &&
        <Typography
          className={current ? ` ${classes.menuItem} active` : `${classes.menuItem}`}
          onClick={(event) => handleClick(event)}
        >
          {itemName}
          <IconButton
            aria-label="dropdown menu"
            aria-controls="menu-appBar"
            aria-haspopup="true" color="inherit"
          >
            <DownArrowIcon />
          </IconButton>
        </Typography>
      }

      {avatarIcon &&
        <IconButton
          onClick={(event) => handleClick(event)}
          aria-label="dropdown menu"
          aria-controls="menu-appBar"
          aria-haspopup="true"
          color="inherit"
        >
          <Avatar alt={`${firstName}-${lastName}`} src={profileUrl || ""}></Avatar>
        </IconButton>
      }

      <Menu
        id="menu-appBar"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >

        {menuItem && menuItem.map((item) =>
          <Link key={item.link} to={item.link} className={classes.menuLink}>
            <MenuItem onClick={handleClose}>{item.name}</MenuItem>
          </Link>
        )}

        {avatarIcon &&
          <Box px={2} pt={1} minWidth={360} maxWidth={360}>
            <Grid container spacing={3}>
              <Grid item md={6}>
                <Box display="flex" alignItems="center">
                  <MenuSettingIcon />

                  <Box ml={1}>
                    <Typography variant="h5">{GENERAL}</Typography>
                  </Box>
                </Box>

                <Box mt={1}>
                  {GENERAL_MENU_ITEMS && GENERAL_MENU_ITEMS.map((item) =>
                    <Link key={item.link} to={item.link}>
                      <MenuItem onClick={handleClose}>{item.name}</MenuItem>
                    </Link>
                  )}
                </Box>
              </Grid>

              <Grid item md={6}>
                <Box display="flex" alignItems="center">
                  <MenuShieldIcon />

                  <Box ml={1}>
                    <Typography variant="h5">{SECURITY}</Typography>
                  </Box>
                </Box>

                <Box mt={1}>
                  {SECURITY_MENU_ITEMS && SECURITY_MENU_ITEMS.map((item) =>
                    <Link key={item.link} to={item.link}>
                      <MenuItem onClick={handleClose}>{item.name}</MenuItem>
                    </Link>
                  )}
                </Box>
              </Grid>
            </Grid>

            <Box display="flex" justifyContent="flex-end" mt={2} pt={2} pb={1} borderTop={`1px solid ${WHITE_FOUR}`}>
              <Button
                onClick={() => logoutUser()}
                variant="outlined"
                color="inherit"
                size="small"
                className="danger"
              >
                {LOGOUT_TEXT}
              </Button>
            </Box>
          </Box>
        }
      </Menu>
    </>
  );
};

export default DropdownMenu;
