// packages block
import { FC } from "react";
import { Box, colors, Typography } from "@material-ui/core";
// constants, login styles and interfaces block
import history from "../../history";
import { Children } from "../../interfacesTypes";
import { useLoginStyles } from "../../styles/loginStyles";
import {
  FORGET_PASSWORD_ROUTE, FORGOT_PASSWORD_TEXT, LOGIN_ROUTE, ROOT_ROUTE, SET, SET_PASSWORD_ROUTE, SIGN_IN
} from "../../constants";

const AuthLayout: FC<Children> = ({ children }): JSX.Element => {
  const classes = useLoginStyles();
  const { location: { pathname } } = history;
  let title = ''

  if (pathname === LOGIN_ROUTE || pathname === ROOT_ROUTE) {
    title = SIGN_IN
  } else if (pathname === FORGET_PASSWORD_ROUTE) {
    title = FORGOT_PASSWORD_TEXT
  } else if (pathname === SET_PASSWORD_ROUTE) {
    title = SET
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.loginFormLoginContainer}>
        <Box pb={2} mb={4} borderBottom={`1px solid ${colors.grey[300]}`}>
          <Typography variant="h4">{title}</Typography>
        </Box>

        {children}
      </Box>
    </Box>
  );
};

export default AuthLayout;
