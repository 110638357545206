// packages block
import { useEffect, useContext } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Box, Button, Grid, CircularProgress } from "@material-ui/core";
//components block
import Alert from "../../common/Alert";
import InputController from '../../../controller';
import CardComponent from "../../common/CardComponent";
// constants, history, styling block
import { AuthContext } from "../../../context";
import { updatePasswordInputs } from "../../../interfacesTypes";
import { updatePasswordSchema } from "../../../validationSchemas";
import { useUpdatePasswordMutation } from "../../../generated/graphql";
import { useProfileDetailsStyles } from "../../../styles/profileDetails";
import {
  CONFIRM_PASSWORD, NEW_PASSWORD, OLD_PASSWORD, OLD_PASSWORD_DID_NOT_MATCH, PASSWORDS_MUST_MATCH,
  PRECONDITION_FAILED_EXCEPTION, RESET_PASSWORD_SUCCESS, SAVE_TEXT
} from "../../../constants";

const ChangePasswordComponent = (): JSX.Element => {
  const { user } = useContext(AuthContext);
  const { id } = user || {};
  const classes = useProfileDetailsStyles()

  const methods = useForm<updatePasswordInputs>({
    mode: "all",
    resolver: yupResolver(updatePasswordSchema)
  });
  const { handleSubmit, reset, setError, clearErrors, watch } = methods;
  const { password, repeatPassword } = watch();

  const [updatePassword, { loading }] = useUpdatePasswordMutation({
    onError({ message }) {
      reset()
      Alert.error(message === PRECONDITION_FAILED_EXCEPTION ?
        OLD_PASSWORD_DID_NOT_MATCH : message
      )
    },

    onCompleted() {
      reset();
      Alert.success(RESET_PASSWORD_SUCCESS);
    }
  })

  const onSubmit: SubmitHandler<updatePasswordInputs> = async ({ oldPassword, password }) => {
    id && await updatePassword({
      variables: {
        updatePasswordInput: {
          id, newPassword: password, oldPassword
        }
      }
    })
  }

  useEffect(() => {
    password === repeatPassword || !!!repeatPassword ?
      clearErrors("repeatPassword")
      : setError("repeatPassword", { message: PASSWORDS_MUST_MATCH })
  }, [clearErrors, password, repeatPassword, setError, watch])

  return (
    <Box className={classes.changePasswordContainer}>
      <Grid container justifyContent='center'>
        <Grid item md={4} sm={12} xs={12}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box className={classes.changePasswordCard}>
                <CardComponent cardTitle="Change Password">
                  <InputController
                    isPassword
                    isRequired
                    fieldType="password"
                    controllerName="oldPassword"
                    controllerLabel={OLD_PASSWORD}
                  />
                  <InputController
                    isPassword
                    isRequired
                    fieldType="password"
                    controllerName="password"
                    controllerLabel={NEW_PASSWORD}
                  />

                  <InputController
                    isPassword
                    isRequired
                    fieldType="password"
                    controllerName="repeatPassword"
                    controllerLabel={CONFIRM_PASSWORD}
                  />

                  <Box display="flex" justifyContent="flex-start" pt={2}>
                    <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>
                      {SAVE_TEXT}

                      {loading && <CircularProgress size={20} color="inherit" />}
                    </Button>
                  </Box>
                </CardComponent>
              </Box>
            </form>
          </FormProvider>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ChangePasswordComponent;
