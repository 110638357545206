// packages block
import { FC, useContext } from "react";
import { Redirect } from "react-router";
import { Box, CssBaseline } from "@material-ui/core";
// components block
import Header from "./Header";
import Loader from "./Loader";
// interfaces/types and main layout styles block
import { getToken } from "../../utils";
import { AuthContext } from "../../context";
import { LOGIN_ROUTE } from "../../constants";
import { MainLayoutProps } from "../../interfacesTypes";
import { useMainLaoutStyles } from "../../styles/mainLayoutStyles";

const MainLayout: FC<MainLayoutProps> = ({ children }): JSX.Element => {
  const { patient } = useContext(AuthContext);
  const classes = useMainLaoutStyles()
  return (
    <>
    {!getToken() && <Redirect to={{ pathname: LOGIN_ROUTE }} />}
    
      {patient ? (<>
        <CssBaseline />
        <Header />

        <Box className={classes.mainLayoutContainer}>
          <Box component="main" flex={1}>
            <Box minHeight="calc(100vh - 170px)" paddingBottom={3}>
              {children}
            </Box>
          </Box>
        </Box>
      </>) : <Loader loading={true} />}
    </>
  )
};

export default MainLayout;
