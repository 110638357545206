// packages block
import { Box, Grid, Typography } from "@material-ui/core";
// utils, styles and constants block
import { WHITE, } from "../../../theme";
import { sanitizedData } from "../../../utils";
import { useWelcomeStyles } from "../../../styles/welcomeStyles";
import { TESTING_DATA, TESTING_DESCRIPTION, TESTING_TITLE } from "../../../constants";

const TestingComponent = (): JSX.Element => {
  const classes = useWelcomeStyles();

  return (
    <Box bgcolor={WHITE} pt={3}>
      <Box className={classes.containerBox}>
        <Grid container spacing={4} alignItems="center">
          <Grid item md={10} sm={12} xs={12}>
            <Box mb={3} className={classes.bigBorder} />

            <Typography variant="h4" className={classes.title}>{TESTING_TITLE}</Typography>

            <Box mt={4} py={2} dangerouslySetInnerHTML={sanitizedData(TESTING_DESCRIPTION)} />

            <Grid container spacing={0}>
              {TESTING_DATA.map((item, index) => {
                return (
                  <Grid item key={index} md={12} sm={12} xs={12}>
                    <Box p={2} display="flex" alignItems="center">
                      <Box className={classes.listIcon} />

                      <Box ml={1} dangerouslySetInnerHTML={sanitizedData(item)} />
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default TestingComponent;