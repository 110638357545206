// packages block
import { Box, Grid, Typography } from "@material-ui/core";
// constants, history, styling block
import { WHITE } from "../../../theme";
import { useWelcomeStyles } from "../../../styles/welcomeStyles";
import { YOU_CAN_DATA, YOU_CAN_TITLE } from "../../../constants";

const YouCanComponent = (): JSX.Element => {
  const classes = useWelcomeStyles();

  return (
    <Box bgcolor={WHITE} py={5}>
      <Box className={classes.containerBox}>
        <Grid container spacing={4} alignItems="center">
          <Grid item md={12} sm={12} xs={12}>
            <Box mb={3} className={classes.bigBorder}></Box>
            <Typography variant="h4" className={classes.title}>{YOU_CAN_TITLE}</Typography>
            <Box p={3} />
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          {YOU_CAN_DATA.map((item, index) => {
            return (
              <Grid item key={index} md={6} sm={12} xs={12}>
                <Box py={2} display="flex" alignItems="center">
                  <Box className={classes.listIcon} />

                  <Box ml={1}>
                    <Typography variant="body1">{item}</Typography>
                  </Box>
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </Box>
  )
}

export default YouCanComponent;