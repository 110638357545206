import { makeStyles, createStyles } from "@material-ui/core";
import { GREEN, theme, WHITE, WHITE_FOUR } from "../theme";
import HERO_IMAGE from "../assets/images/hero-new.jpg";
import COVID_IMAGE from "../assets/images/covid-image.png";

export const useWelcomeStyles = makeStyles(() =>
  createStyles({
    containerBox: {
      width: "80%",
      margin: "50px auto",
    },

    bigBorder: {
      backgroundColor: GREEN,
      width: 90,
      height: 7,
    },

    smallBorder: {
      backgroundColor: GREEN,
      width: 50,
      height: 2,
    },

    title: {
      fontSize: 40,
    },

    description: {
      fontSize: 16,
      maxWidth: "80%",
    },

    outerBox: {
      border: `1px solid ${GREEN}`,
      borderRadius: 6,
      padding: theme.spacing(4),
      minWidth: 220,
      margin: 15,
    },

    iconBox: {
      backgroundColor: GREEN,
      borderRadius: 10,
      padding: "22 19",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 70,
      height: 76,
    },

    iconBoxSymptoms: {
      backgroundColor: GREEN,
      borderRadius: 100,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 100,
      height: 100,
    },

    listIcon: {
      width: 12,
      height: 12,
      minWidth: 12,
      minHeight: 12,
      backgroundColor: GREEN,
      borderRadius: "100%",
    },

    heroBox: {
      backgroundImage: `url(${HERO_IMAGE})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      width: "100%",
      height: "100%",
      minHeight: "880px",
      textAlign: "center",
    },

    covidBox: {
      backgroundImage: `url(${COVID_IMAGE})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      width: "100%",
      height: "100%",
      minHeight: "700px",
      textAlign: "center",
    },

    heroTitle: {
      fontSize: 50,
      color: WHITE,
      animation: `$myEffect 3000ms ${theme.transitions.easing.easeInOut}`,
    },

    "@keyframes myEffect": {
      "0%": {
        opacity: 0,
        transform: "translateY(-200%)"
      },
      "100%": {
        opacity: 1,
        transform: "translateY(0)"
      }
    },

    heroDescription: {
      fontWeight: 300,
      fontSize: 24,
      lineHeight: "35px",
      letterSpacing: 1,
      color: WHITE_FOUR,
      animation: `$myEffectOne 3000ms ${theme.transitions.easing.easeInOut}`,
    },

    "@keyframes myEffectOne": {
      "0%": {
        opacity: 0,
        transform: "translateX(-100%)"
      },
      "100%": {
        opacity: 1,
        transform: "translateX(0)"
      }
    },

    buttonLink: {
      width: 260,
      minHeight: 70,
      margin: 10,
      animation: `$myEffectTwo 3000ms ${theme.transitions.easing.easeInOut}`,

      "& .MuiButton-label": {
        fontSize: 18,
      },
    },

    "@keyframes myEffectTwo": {
      "0%": {
        opacity: 0,
        transform: "translateZ(200%)"
      },
      "100%": {
        opacity: 1,
        transform: "translateZ(0)"
      }
    },

    footerItem: {
      fontSize: 14,
      fontWeight: 600,
      color: WHITE,
      lineHeight: "20px",
    },

    footerCopy: {
      fontSize: 14,
      fontWeight: 400,
      color: WHITE,
      lineHeight: "20px",
    },
  })
);
