// packages block
import { useEffect } from "react";
//components block
import HeroComponent from "./hero";
import StayComponent from "./stay";
import WhatComponent from "./what";
import CasesComponent from "./cases";
import TestingComponent from "./testing";
import SymptomsComponent from "./symptoms";
import PreventionComponent from "./prevention";
import HeaderComponent from "../../common/WelcomePage/Header";
import FooterComponent from "../../common/WelcomePage/Footer";
// utils and constants block
import history from "../../../history";
import { getToken } from "../../../utils";
import { DASHBOARD_ROUTE } from "../../../constants";

const CovidComponent = (): JSX.Element => {
  useEffect(() => {
    getToken() && history.push(DASHBOARD_ROUTE)
  }, []);

  return (
    <>
      <HeaderComponent />
      <HeroComponent />
      <StayComponent />
      <CasesComponent />
      <WhatComponent />
      <SymptomsComponent />
      <PreventionComponent />
      <TestingComponent />
      <FooterComponent />
    </>
  )
}

export default CovidComponent;