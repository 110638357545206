// packages block
import { useContext, useEffect } from "react";
import { withRouter } from "react-router";
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from "react-hook-form";
import { Button, CircularProgress } from "@material-ui/core";
// components block
import AuthLayout from "../AuthLayout";
import Alert from "../../common/Alert";
import LoginController from "./LoginController";
import FooterComponent from "../../common/WelcomePage/Footer";
import HeaderComponent from "../../common/WelcomePage/Header";
// history, context, constants, graphql, and utils
import history from "../../../history";
import { requiredLabel } from "../../../utils";
import { AuthContext } from "../../../context";
import { loginValidationSchema } from "../../../validationSchemas";
import { LoginUserInput, useLoginMutation } from "../../../generated/graphql";
import {
  EMAIL, EMAIL_CHANGED_OR_NOT_VERIFIED_MESSAGE, EXCEPTION, FORBIDDEN_EXCEPTION, PASSWORD_LABEL,
  SIGN_IN, TOKEN, WRONG_EMAIL_OR_PASSWORD, DASHBOARD_ROUTE, SOMETHING_WENT_WRONG, LOGIN_SUCCESSFULLY,
  ONLY_PATIENT_MESSAGE, USER_NOT_FOUND_EXCEPTION_MESSAGE, NOT_FOUND_EXCEPTION,
} from "../../../constants";

const LoginComponent = (): JSX.Element => {
  const { setIsLoggedIn } = useContext(AuthContext);
  const { control, handleSubmit, formState: { errors } } = useForm<LoginUserInput>({
    defaultValues: {
      email: "",
      password: "",
    },

    resolver: yupResolver(loginValidationSchema)
  });

  const [login, { loading }] = useLoginMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,

    onError({ message }) {
      Alert.error(message === FORBIDDEN_EXCEPTION || message === EXCEPTION
        ? EMAIL_CHANGED_OR_NOT_VERIFIED_MESSAGE : message === NOT_FOUND_EXCEPTION
          ? USER_NOT_FOUND_EXCEPTION_MESSAGE : message
      )
    },

    onCompleted(data) {
      if (data) {
        const { login: { response, access_token, roles } } = data
        if (response) {
          const { status } = response

          if (status === 404) {
            return Alert.error(WRONG_EMAIL_OR_PASSWORD);
          }

          if (status === 200 && access_token && roles) {
            const isPatient = roles.filter(role => role.role?.toString() === 'patient')

            if (!!isPatient?.length) {
              localStorage.setItem(TOKEN, access_token);
              setIsLoggedIn(true);
              Alert.success(LOGIN_SUCCESSFULLY)
              history.push(DASHBOARD_ROUTE);
            } else {
              Alert.error(ONLY_PATIENT_MESSAGE)
            }
          } else {
            Alert.error(SOMETHING_WENT_WRONG)
          }
        }
      }
    }
  });

  const onSubmit: SubmitHandler<LoginUserInput> = async (data) => {
    await login({
      variables: {
        loginUser: data,
      },
    });
  };

  useEffect(() => {
    localStorage.getItem(TOKEN) && history.push(DASHBOARD_ROUTE)
  }, []);

  const { email: { message: emailError } = {}, password: { message: passwordError } = {} } = errors;

  return (
    <>
      <HeaderComponent />

      <AuthLayout>
        <form onSubmit={handleSubmit(onSubmit)}>
          <LoginController
            control={control}
            controllerName="email"
            controllerLabel={requiredLabel(EMAIL)}
            fieldType="email"
            error={emailError}
          />

          <LoginController
            control={control}
            controllerName="password"
            isPassword={true}
            controllerLabel={requiredLabel(PASSWORD_LABEL)}
            fieldType="password"
            error={passwordError}
          />

          <Button type="submit" variant="contained" color="inherit" fullWidth disabled={loading}>
            {SIGN_IN}

            {loading && <CircularProgress size={20} color="inherit" />}
          </Button>
        </form>
      </AuthLayout>

      <FooterComponent />
    </>
  );
};

export default withRouter(LoginComponent);
