// packages block
import { FC } from "react";
import { Box, Typography } from "@material-ui/core";
// svgs and constants block
import { LOGIN_SUCCESSFULLY, LOREM_TEXT_15 } from "../../../constants";
import DASHBOARD_IMAGE from "../../../assets/images/dashboard-image.svg";

const DashboardComponent: FC = (): JSX.Element => (
  <Box sx={{ p: 5 }}>
    <Box pb={1}>
      <Typography component="h3" variant="h3">{LOGIN_SUCCESSFULLY}</Typography>
    </Box>

    <Box className="subHeading">{LOREM_TEXT_15}</Box>
    <Box display="flex" justifyContent="flex-end">
      <img src={DASHBOARD_IMAGE} alt="" />
    </Box>
  </Box>
);

export default DashboardComponent;
