// packages block
import * as yup from "yup";
// utils and constants block
import { invalidMessage, requiredMessage } from "../utils";
import {
  ADDRESS, CITY, CONFIRM_YOUR_PASSWORD, COUNTRY, EMAIL, MaxLength, MinLength,
  FIRST_NAME, INVALID_EMAIL, PASSWORDS_MUST_MATCH, PASSWORD_TEXT, ZIP_VALIDATION_MESSAGE,
  PASSWORD_REGEX, PASSWORD_VALIDATION_MESSAGE, STATE, ZIP_CODE, OLD_PASSWORD,
  PHONE, STRING_REGEX, PRIMARY_INSURANCE, SECONDARY_INSURANCE, APPOINTMENT, ADDRESS_REGEX, ZIP_REGEX,
  PASSWORD_LABEL,
  NO_WHITE_SPACING_AT_BOTH_ENDS_ERROR_MESSAGE,
  NO_SPACE_AT_BOTH_ENDS_REGEX,
  NO_SPECIAL_CHAR_ERROR_MESSAGE,
  NO_SPECIAL_CHAR_REGEX,
  NO_NUMBER_ERROR_MESSAGE,
} from "../constants";

// Validation Helpers
const notRequiredStringOnly = (label: string) => {
  return yup.string()
    .test('', invalidMessage(label), value => !value ? true : STRING_REGEX.test(value))
}

const addressValidation = (isRequired: boolean) => {
  return yup.string()
    .test('', requiredMessage(ADDRESS), value => isRequired ? !!value : true)
    .test(
      '', invalidMessage(ADDRESS), value => !value ? !value : ADDRESS_REGEX.test(value)
    )
}

const requiredStringOnly = (label: string, min: number, max: number) => {
  return yup.string()
    .test('', requiredMessage(label), value => !!value)
    .test('', invalidMessage(label), value => value ? STRING_REGEX.test(value) : false)
    .test('', MinLength(label, min), value => value ? value.length >= min : false)
    .test('', MaxLength(label, max), value => value ? value.length <= max : false)
}

const zipCodeSchema = (isRequired: boolean) => {
  return yup.string()
    .test('', requiredMessage(ZIP_CODE), value => isRequired ? !!value : true)
    .test('', ZIP_VALIDATION_MESSAGE, value => value ? ZIP_REGEX.test(value) : false)
}

const stateSchema = (isRequired: boolean) => {
  return yup.object().shape({
    name: yup.string(),
    id: yup.string()
  }).test('', requiredMessage(STATE), value => isRequired ? !!value : true)
}

const countrySchema = (isRequired: boolean) => {
  return yup.object().shape({
    name: yup.string(),
    id: yup.string()
  }).test('', requiredMessage(COUNTRY), value => isRequired ? !!value : true)
}

const emailSchema = (isRequired: boolean) => {
  return yup.string().email(INVALID_EMAIL)
    .test('', requiredMessage(EMAIL), value => isRequired ? !!value : true)
}

// Schemas
const passwordSchema = { password: yup.string().required(requiredMessage(PASSWORD_LABEL)) }
const phoneSchema = (isRequired: boolean, label: string) => {
  return yup.string()
    .test('', requiredMessage(label), value => isRequired ? !!value : true)
    .test('', MinLength(label, 10), value => !value ? true : !!value && value.length >= 10)
}

const serviceIdSchema = {
  serviceId: yup.object().shape({
    name: yup.string().required(),
    id: yup.string().required()
  }).test('', requiredMessage(APPOINTMENT), ({ id }) => !!id)
}

const passwordAndRepeatPasswordSchema = {
  password: yup.string().required(requiredMessage(PASSWORD_TEXT))
    .matches(PASSWORD_REGEX, PASSWORD_VALIDATION_MESSAGE),

  repeatPassword: yup.string().oneOf([yup.ref("password"), null], PASSWORDS_MUST_MATCH)
    .required(CONFIRM_YOUR_PASSWORD),
}

const generalNameSchema = (
  isRequired: boolean, label: string, allowNumber: boolean, allowSpecial: boolean, maxLength: number = 50
) => (
  yup.string()
    .test('', requiredMessage(label), value => isRequired ? !!value : true)
    .test('', NO_WHITE_SPACING_AT_BOTH_ENDS_ERROR_MESSAGE,
      value => value ? NO_SPACE_AT_BOTH_ENDS_REGEX.test(value) : true)
    .test('', NO_SPECIAL_CHAR_ERROR_MESSAGE,
      value => allowSpecial ? true : value ? NO_SPECIAL_CHAR_REGEX.test(value) : true)
    .test('', NO_NUMBER_ERROR_MESSAGE,
      value => allowNumber ? true : value ? STRING_REGEX.test(value) : true)
    .min(isRequired ? 2 : 0, MinLength(label, 2)).max(maxLength, MaxLength(label, maxLength))
)

const firstLastNameSchema = {
  lastName: generalNameSchema(false, FIRST_NAME, false, false, 15),
  firstName: generalNameSchema(false, FIRST_NAME, false, false, 15),
};

export const loginValidationSchema = yup.object({
  email: emailSchema(true),
  ...passwordSchema
});

export const resetPasswordValidationSchema = yup.object({
  ...passwordAndRepeatPasswordSchema
});

export const forgetPasswordValidationSchema = yup.object({
  email: emailSchema(true),
});

export const appointmentSchema = yup.object({
  ...serviceIdSchema,
  notes: yup.string(),
  primaryInsurance: notRequiredStringOnly(PRIMARY_INSURANCE),
  secondaryInsurance: notRequiredStringOnly(SECONDARY_INSURANCE),
})

export const updatePasswordSchema = yup.object({
  ...passwordAndRepeatPasswordSchema,
  oldPassword: yup.string().required(requiredMessage(OLD_PASSWORD)),
})

export const updateProfileSchema = yup.object({
  ...firstLastNameSchema,
  email: emailSchema(true),
  state: stateSchema(true),
  zipCode: zipCodeSchema(true),
  country: countrySchema(true),
  phone: phoneSchema(true, PHONE),
  address: addressValidation(true),
  city: requiredStringOnly(CITY, 2, 20),
})
