// packages block
import { ChangeEvent, FC, Reducer, useCallback, useContext, useEffect, useReducer } from "react";
import dotenv from 'dotenv';
import { Box, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";

// components block
import Search from "./Search";
import CardComponent from "./CardComponent";
import AppointmentList from "./AppointmentList";
import NoDataFoundComponent from "./NoDataFoundComponent";
// graphql, constants, context, interfaces/types, reducer and utils block
import { AuthContext } from "../../context";
import { useTableStyles } from "../../styles/tableStyles";
import { AppointmentSearchingTooltipData, APPOINTMENT_TABS, PAGE_LIMIT, PAST_APPOINTMENTS, UPCOMING_APPOINTMENTS } from "../../constants";
import {
  appointmentReducer, Action, initialState, State, ActionType
} from "../../reducers/appointmentReducer";
import {
  AppointmentsPayload, useGetUpcomingAppointmentsLazyQuery,
} from "../../generated/graphql";
import { Pagination } from "@material-ui/lab";
import { ParamsType } from "../../interfacesTypes";
import { useParams } from "react-router";

dotenv.config()

const AppointmentsTable: FC = (): JSX.Element => {
  const classes = useTableStyles()
  const { user } = useContext(AuthContext)
  const { userId } = user || {};
  const { tabValue: routeParamValue } = useParams<ParamsType>();

  const [state, dispatch] = useReducer<Reducer<State, Action>>(appointmentReducer, initialState)
  const { pageComing, upComing, completed, pageCompleted, totalPagesComing,
    totalPagesCompleted, searchComingQuery, searchPastQuery, tabValue } = state

  const [fetchAppointments, { loading: upcomingAppointmentsLoading, error: upcomingAppointmentsError }] = useGetUpcomingAppointmentsLazyQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onError() {
      dispatch({ type: ActionType.SET_COMPLETED, completed: [] });
    },

    onCompleted(data) {
      const { findAllUpcomingAppointments } = data || {};
      if (findAllUpcomingAppointments) {
        const { appointments, pagination } = findAllUpcomingAppointments

        if (pagination) {
          const { totalPages } = pagination
          totalPages && dispatch({ type: ActionType.SET_TOTAL_PAGES_COMING, totalPagesComing: totalPages })
        }

        !!appointments && dispatch({
          type: ActionType.SET_UP_COMING,
          upComing: appointments as AppointmentsPayload['appointments']
        });
      }
    }
  });

  const [fetchPastAppointments, { loading: fetchPastAppointmentsLoading, error: fetchPastAppointmentsError }] = useGetUpcomingAppointmentsLazyQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onError() {
      dispatch({ type: ActionType.SET_COMPLETED, completed: [] });
    },

    onCompleted(data) {
      const { findAllUpcomingAppointments } = data || {};
      if (findAllUpcomingAppointments) {
        const { appointments, pagination } = findAllUpcomingAppointments

        if (pagination) {
          const { totalPages } = pagination
          totalPages && dispatch({ type: ActionType.SET_TOTAL_PAGES_COMPLETED, totalPagesCompleted: totalPages })
        }

        !!appointments && !!completed && dispatch({
          type: ActionType.SET_COMPLETED,
          completed: appointments as AppointmentsPayload['appointments']
        });
      }
    }
  });

  const fetchComing = useCallback(async () => {
    try {
      userId && await fetchAppointments({
        variables: {
          upComingAppointmentsInput: {
            patientId: userId,
            shouldFetchPast: false,
            searchString: searchComingQuery,
            paginationOptions: {
              limit: PAGE_LIMIT, page: pageComing
            },
          }
        }
      })
    } catch (error) { }
  }, [userId, fetchAppointments, searchComingQuery, pageComing])

  const fetchPast = useCallback(async () => {
    try {
      userId && await fetchPastAppointments({
        variables: {
          upComingAppointmentsInput: {
            patientId: userId,
            shouldFetchPast: true,
            searchString: searchPastQuery,
            paginationOptions: {
              limit: PAGE_LIMIT, page: pageCompleted,
            },
          }
        }
      })
    } catch (error) { }
  }, [userId, fetchPastAppointments, searchPastQuery, pageCompleted])

  useEffect(() => {
    fetchComing();
    fetchPast();
  }, [fetchComing, fetchPast]);


  const searchUpcoming = (query: string) => {
    dispatch({ type: ActionType.SET_COMING_SEARCH_QUERY, searchComingQuery: query })
    dispatch({ type: ActionType.SET_TOTAL_PAGES_COMING, totalPagesComing: 0 })
    dispatch({ type: ActionType.SET_PAGE_COMING, pageComing: 1 })
  }

  const searchPast = (query: string) => {
    dispatch({ type: ActionType.SET_PAST_SEARCH_QUERY, searchPastQuery: query })
    dispatch({ type: ActionType.SET_TOTAL_PAGES_COMPLETED, totalPagesCompleted: 0 })
    dispatch({ type: ActionType.SET_PAGE_COMPLETED, pageCompleted: 1 })
  }

  const handleComingChange = (_: ChangeEvent<unknown>, value: number) => dispatch({
    type: ActionType.SET_PAGE_COMING, pageComing: value
  });

  const handlePastChange = (_: ChangeEvent<unknown>, value: number) => dispatch({
    type: ActionType.SET_PAGE_COMPLETED, pageCompleted: value
  });

  const handleChange = (_: ChangeEvent<{}>, newValue: string) =>
    dispatch({ type: ActionType.SET_TAB_VALUE, tabValue: newValue })

  useEffect(() => {
    routeParamValue &&
      dispatch({ type: ActionType.SET_TAB_VALUE, tabValue: routeParamValue })
  }, [routeParamValue])

  return (
    <Box className={classes.tabWrapper} pt={2}>
      <TabContext value={tabValue}>
        <TabList onChange={handleChange}
          variant="scrollable"
          aria-label="Profile top tabs">
          {APPOINTMENT_TABS.map(item => (
            <Tab
              classes={{ wrapper: classes.tab }} key={`${item.title}-${item.value}`} label={item.title} value={item.value}
            />
          ))}
        </TabList>

        <TabPanel value="1">
          <Box className={classes.searchContainer}>
            <Search info search={searchUpcoming} tooltipData={AppointmentSearchingTooltipData} />
          </Box>

          <Box maxHeight={`calc(100vh - 320px)`} overflow='auto'>
            <CardComponent cardTitle={UPCOMING_APPOINTMENTS}>
              <AppointmentList appointments={upComing} reload={() => fetchComing()} />

              {((!upcomingAppointmentsLoading && upComing?.length === 0) || upcomingAppointmentsError) && (
                <Box display="flex" justifyContent="center" pb={12} pt={5}>
                  <NoDataFoundComponent />
                </Box>
              )}

              {
                totalPagesComing > 1 &&
                <Box display="flex" justifyContent="flex-end" p={3}>
                  <Pagination
                    shape="rounded"
                    variant="outlined"
                    page={pageComing}
                    count={totalPagesComing}
                    onChange={handleComingChange}
                  />
                </Box>
              }
            </CardComponent>

          </Box>
        </TabPanel>

        <TabPanel value="2">
          <Box maxHeight={`calc(100vh - 320px)`} overflow='auto'>
            <Box className={classes.searchContainer}>
              <Search search={searchPast} info tooltipData={AppointmentSearchingTooltipData} />
            </Box>

            <CardComponent cardTitle={PAST_APPOINTMENTS}>
              <AppointmentList appointments={completed} past reload={() => fetchPast()} />

              {((!fetchPastAppointmentsLoading && completed?.length === 0) || fetchPastAppointmentsError) && (
                <Box display="flex" justifyContent="center" pb={12} pt={5}>
                  <NoDataFoundComponent />
                </Box>
              )}

              {
                totalPagesCompleted > 1 &&
                <Box display="flex" justifyContent="flex-end" p={3}>
                  <Pagination
                    shape="rounded"
                    variant="outlined"
                    page={pageCompleted}
                    count={totalPagesCompleted}
                    onChange={handlePastChange}
                  />
                </Box>
              }
            </CardComponent>
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default AppointmentsTable;
